import { IAuthenticateService } from './service/authenticate/types'
import { IProductsService } from './service/products/types'
import { IConfigService } from './service/config/types'
import { IClassificationService } from './service/classification/types'
import { ICustomersService } from './service/customers/types'
import { ICartMode, ICartService } from './service/carts/types'
import { IOrderMode, IOrdersService } from './service/orders/types'
import { IFavoriteService } from './service/favorite/types'
import { ICmsService } from './service/cms/types'
import { IInvoicesService } from './service/invoices/types'
import { ISearchSuggestionService } from './service/search/types'
import { IOrderExportService } from './service/orderExports/types'
import { ICartExportService } from './service/cartExports/types'
import { IExportService } from './service/exports/types'
import { IShopImportService } from './service/imports/types'
import { IShopImportCartService } from './service/cartImports/types'
import { IShopImportCustomerStockService } from './service/customerStocks/types'
import { ISalesmanService } from './service/salesman/types'
import { IStatisticsService } from './service/stats/types'
import { IStockAlertService } from './service/stockAlert/types'
import { AxiosInstance } from 'axios'
import { IMeService } from './service/me/types'
import { IOrderAnomalyService } from './service/orderAnomaly/types'
import { IPictureExportService } from './service/pictureExport/types'
import { ILocalizationService } from './service/localization/types'
import { IProspectsService } from './service/prospects/types'
import { IPaymentModeService } from './service/payment-modes/types'
import { IPlanogramsService } from './service/planograms/types'
import { IProductGridPublicsService } from './service/product-grid-publics/types'
import { IResetPasswordService } from './service/resetPasswordRequest/types'

/*******************************
 * BASE API
 * ****************************/
export type IApiBooleanType = 1 | 0 | true | false | '1' | '0'
export const SELECTED_CUSTOMER_HEADER_NAME = 'X-Customer'
export const CART_MODE_HEADER_NAME = 'X-Cart-Mode'
export const ORDER_MODE_HEADER_NAME = 'X-Order-Mode'

export enum ApiListOrderDirection {
    Asc = 'asc',
    Desc = 'desc',
}

export interface IApiResource<T = string> {
    readonly '@id': string
    readonly '@context'?: string
    readonly '@type': T
}

export interface IApiErrorViolation {
    message: string
    propertyPath: string
}
export interface IApiErrorCollection extends IApiResource {
    'hydra:description': number
    'hydra:title': number
    violations: Array<IApiErrorViolation>
}

export type IBaseApiResponse = IApiResource

export interface ICollectionApiResponse<S> extends IBaseApiResponse {
    readonly 'hydra:member': S
    readonly 'hydra:totalItems': number
}

export interface IBasePaginationParameters {
    page?: number
    itemsPerPage?: number
    search?: string
    order?: Record<string, 'desc' | 'asc'>
}

export interface IPaginationApiResponse<S> extends ICollectionApiResponse<S> {
    readonly 'hydra:view': IApiResource & {
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:prev'?: string
        'hydra:next'?: string
    }
}

/*******************************
 * BASE DES FICHIERS API
 * ****************************/
export interface IApiFileLink extends IApiResource {
    readonly url: string
}

/*******************************
 * CLIENT API
 * ****************************/
export interface IApiClient {
    readonly authenticate: IAuthenticateService
    readonly products: IProductsService
    readonly planograms: IPlanogramsService
    readonly config: IConfigService
    readonly classification: IClassificationService
    readonly customers: ICustomersService
    readonly prospects: IProspectsService
    readonly carts: ICartService
    readonly cartExports: ICartExportService
    readonly cartImports: IShopImportCartService
    readonly customerStocks: IShopImportCustomerStockService
    readonly orders: IOrdersService
    readonly orderExports: IOrderExportService
    readonly orderAnomaly: IOrderAnomalyService
    readonly invoices: IInvoicesService
    readonly favorite: IFavoriteService
    readonly cms: ICmsService
    readonly search: ISearchSuggestionService
    readonly exports: IExportService
    readonly imports: IShopImportService
    readonly salesman: ISalesmanService
    readonly paymentModes: IPaymentModeService
    readonly statistics: IStatisticsService
    readonly stockAlert: IStockAlertService
    readonly me: IMeService
    readonly pictureExport: IPictureExportService
    readonly localization: ILocalizationService
    readonly productGridPublics: IProductGridPublicsService
    readonly resetPassword: IResetPasswordService
    readonly setLocale: (locale: string) => void
    readonly setCustomerId: (customer?: string) => void
    readonly setCartMode: (cartMode?: ICartMode) => void
    readonly setOrderMode: (orderMode?: IOrderMode) => void
    readonly getInstance: () => AxiosInstance
    readonly getRefreshTokenUrl: () => string
}
