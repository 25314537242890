/*
 *
 * Auth reducer
 *
 */

import ActionTypes from './constants'
import { ContainerActions, ContainerState } from './types'
import { isLoggedIn } from '../../services/api/service/authenticate/jwt'

export const initialState: ContainerState = {
    login: {
        fetching: false,
        require2fa: false,
    },
    check2fa: {
        fetching: false,
    },
    require2fa: false,
    changePassword: {
        fetching: false,
    },
    requireChangePassword: false,
    logged: isLoggedIn(),
    fetching: false,
}

function authReducer(state: ContainerState = initialState, action: ContainerActions): ContainerState {
    switch (action.type) {
        case ActionTypes.RESET_ACTION:
            return initialState
        case ActionTypes.LOGOUT_ACTION:
            return {
                ...state,
            }
        case ActionTypes.PROCESS_ACTION:
            return {
                ...state,
                login: {
                    fetching: true,
                    require2fa: false,
                    params: action.payload,
                },
                logged: false,
            }
        case ActionTypes.FAILURE_ACTION:
            return {
                ...state,
                login: {
                    require2fa: false,
                    tms: Date.now(),
                    fetching: false,
                    error: action.payload.error,
                },
                logged: false,
            }
        case ActionTypes.WAIT2FA_ACTION:
            return {
                ...state,
                login: {
                    ...state.login,
                    tms: Date.now(),
                    fetching: false,
                },
                check2fa: {
                    ...state.check2fa,
                    ...action.payload,
                    fetching: false,
                },
                require2fa: true,
            }
        case ActionTypes.FAILURE_2FA_ACTION:
            return {
                ...state,
                check2fa: {
                    ...state.check2fa,
                    tms: Date.now(),
                    fetching: false,
                    error: action.payload.error,
                },
            }
        case ActionTypes.WAIT_CHANGE_PASSWORD_ACTION:
            return {
                ...state,
                login: {
                    ...state.login,
                    tms: Date.now(),
                    fetching: false,
                },
                changePassword: {
                    ...state.changePassword,
                    ...action.payload,
                    fetching: false,
                },
                require2fa: false,
                requireChangePassword: true,
            }
        case ActionTypes.FAILURE_CHANGE_PASSWORD_ACTION:
            return {
                ...state,
                changePassword: {
                    ...state.changePassword,
                    tms: Date.now(),
                    fetching: false,
                    error: action.payload.error,
                },
            }
        case ActionTypes.LOGGED_ACTION:
            return {
                ...state,
                require2fa: false,
                requireChangePassword: false,
                logged: isLoggedIn(),
                me: action.payload.me,
                redirectUrl: action.payload.redirectUrl,
                originUrl: action.payload.originUrl,
            }
        default:
            return state
    }
}

export default authReducer
