import React, { FunctionComponent, memo, useState } from 'react'
import { Alert, Form, Spinner } from 'react-bootstrap'
import { FieldErrors, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { generatePath, useHistory } from 'react-router-dom'
import * as yup from 'yup'
import Config from '../../config'
import Logo from '../../assets/svg/logo-u10.svg'
import { yupResolver } from '@hookform/resolvers/yup'
import LoginWindowFooter from '../LoginWindow/LoginWindowFooter'
import classNames from 'classnames'
import { isInternetExplorer } from '../../utils/browser'
import Button from '../../components/Buttons/Button'
import { has, get } from 'lodash'
import SweetAlert from 'react-bootstrap-sweetalert'
import { sleep } from '../../utils/sleep'
import { getPath } from '../../routes'
import client from '../../services/api/client'
import { formatErrorDescription } from '../../store/http/utils'
import { isAxiosError, isConstraintViolationList } from '../../services/api/utils'
import { IApiErrorDescription } from '../../store/http/types'

const ForgotPasswordWindow: FunctionComponent = (): JSX.Element => {
    const history = useHistory()
    const { formatMessage, locale } = useIntl() //hook react-intl permettant de récupérer l'api FormattedMessage
    const isIe = isInternetExplorer()
    const [globalError, setGlobalError] = useState<IApiErrorDescription | undefined>(undefined)
    const [isProcessing, setIsProcessing] = useState(false)
    const [isShowSuccess, setIsShowSuccess] = useState(false)
    const validationSchema = yup.object({
        username: yup.string().required(formatMessage({ id: 'login.login_required' })),
    })

    const {
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        reset,
        formState: { errors, isSubmitting },
    } = useForm<yup.InferType<typeof validationSchema>>({
        resolver: yupResolver(validationSchema),
    })

    const disabled = isSubmitting || isProcessing
    const isInvalid = has(errors, 'username')
    const fieldError = get(errors, 'username.message')
    const isFullFilled = getValues('username')?.length > 0

    const handleShowConfirmClick = () => {
        setIsShowSuccess(false)
        sleep(800, () => {
            history.replace(generatePath(getPath('login', locale), { lang: locale }))
        })
    }

    const handleOnPreviousButtonClick = () => {
        history.replace(generatePath(getPath('login', locale), { lang: locale }))
    }

    const onFormFailed = async (formErrors: FieldErrors<yup.InferType<typeof validationSchema>>) => {
        // console.log('onFormFailed', formErrors)
    }

    // NOTE DEV: pas de useCallback car cela plante react-hook-form
    const onFormSubmit = async (values: yup.InferType<typeof validationSchema>) => {
        try {
            setIsProcessing(true)
            await client.resetPassword.create(values)
            setIsProcessing(false)
            setIsShowSuccess(true)
        } catch (e) {
            setIsProcessing(false)
            setGlobalError(
                formatErrorDescription(e, formatMessage({ id: 'default.generic_form_error' }), Config.DEBUG.ACTIVE)
            )

            if (isAxiosError(e) && e.response && isConstraintViolationList(e.response.data)) {
                e.response.data.violations.forEach((violation) => {
                    if (violation.propertyPath) {
                        // @ts-ignore
                        setError(violation.propertyPath, {
                            message: violation.message,
                        })
                    }
                })
            }
        }
    }

    const handleFormSubmit = handleSubmit(onFormSubmit, onFormFailed)

    return (
        <div className={'login-window size-lg'}>
            <div className="login-window-body">
                <div className={'login-window-body-heading'}>
                    <div className="heading">
                        <img src={Logo} alt={Config.APP_NAME} />
                        <h1>
                            <FormattedMessage id={'login.forgot_password_title'} />
                        </h1>
                        <p>
                            <FormattedMessage id={'login.forgot_password_explain'} />
                        </p>
                    </div>
                </div>
                <Form
                    noValidate
                    onSubmit={handleFormSubmit}
                    onReset={() => {
                        reset()
                    }}
                >
                    {globalError && (
                        <Alert variant={'danger'} className="mb-4">
                            <p className="mb-0">{globalError.error}</p>
                        </Alert>
                    )}
                    <Form.Group>
                        <div className={classNames('form-group-inner', { 'is-invalid': isInvalid })}>
                            <Form.Control
                                size="lg"
                                type="text"
                                autoComplete="username"
                                spellCheck={false}
                                autoCapitalize={'none'}
                                disabled={disabled}
                                isInvalid={isInvalid}
                                className={classNames({ 'is-invalid': isInvalid }, { 'has-value': isFullFilled })}
                                placeholder={isIe ? formatMessage({ id: 'placeholder.login' }) : ''}
                                {...register('username', { required: true })}
                            />
                            {!isIe && (
                                <span className={'form-control-placeholder'}>
                                    <FormattedMessage id={'placeholder.login'} />
                                </span>
                            )}
                        </div>
                        {isInvalid && <Form.Control.Feedback type="invalid">{fieldError}</Form.Control.Feedback>}
                    </Form.Group>

                    <div className={'login-form-step-footer'}>
                        <Button
                            className={'btn-prev'}
                            variant={'link'}
                            disabled={disabled}
                            onClick={handleOnPreviousButtonClick}
                        >
                            <FormattedMessage id="default.go_back" />
                        </Button>
                        <Button className={'btn-next'} variant="primary" disabled={disabled} type="submit">
                            {isProcessing && (
                                <Spinner
                                    as="span"
                                    className="app-icon mr-1"
                                    animation="grow"
                                    size="sm"
                                    variant="light"
                                    aria-hidden="true"
                                />
                            )}
                            <FormattedMessage id={'login.forgot_password.email.cta'} />
                        </Button>
                    </div>
                </Form>
            </div>
            <LoginWindowFooter />
            <SweetAlert
                customClass="swal-login-success"
                success
                title={formatMessage({
                    id: 'login.forgot_password_sent',
                })}
                show={isShowSuccess}
                onConfirm={handleShowConfirmClick}
                openAnim={{ name: 'fadeInDown', duration: 500 }}
                closeAnim={{ name: 'fadeOutUp', duration: 500 }}
            >
                <FormattedMessage id="login.forgot_password_sent_explain" />
            </SweetAlert>
        </div>
    )
}

export default memo(ForgotPasswordWindow)
