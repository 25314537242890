import { v4 as uuidv4 } from 'uuid'

export const STORAGE_KEY = `device-id-${process.env.NODE_ENV}`

let deviceId: string | null = null

export const getDeviceId = (): string => {
    if (!deviceId) {
        deviceId = localStorage.getItem(STORAGE_KEY)

        if (!deviceId) {
            deviceId = uuidv4()
            localStorage.setItem(STORAGE_KEY, deviceId)
        }
    }

    return deviceId
}
