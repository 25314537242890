import React, { useState } from 'react'
import { passwordValidation } from '../../../utils/yup'
import { FormattedMessage, useIntl } from 'react-intl'
import * as yup from 'yup'
import { FieldErrors, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { isAxiosError, isConstraintViolationList } from '../../../services/api/utils'
import { Alert, Form, Spinner } from 'react-bootstrap'
import classNames from 'classnames'
import { isInvalidField, isValidField } from '../../../utils/validation'
import Button from '../../../components/Buttons/Button'
import MaskedPassword from '../../../components/Form/Fields/Password/MaskedPassword'
import client from '../../../services/api/client'
import { formatErrorDescription } from '../../../store/http/utils'
import Config from '../../../config'
import { IApiErrorDescription } from '../../../store/http/types'

type Props = {
    onDone: () => void
    hash: string
}

export default function ({ onDone, hash }: Props) {
    const [fail, setFail] = useState<IApiErrorDescription | undefined>()
    const [disabled] = useState<boolean>(false)
    const [fetching, setFetching] = useState<boolean>(false)
    const { formatMessage, locale } = useIntl()

    const validationSchema = yup
        .object({
            plain_password: passwordValidation(formatMessage),
            confirm_password: yup
                .string()
                .required()
                .oneOf(
                    [yup.ref('plain_password')],
                    formatMessage({ id: 'app.auth.register.password.global.not_same.error' })
                ),
        })
        .required()

    const defaultValues: yup.InferType<typeof validationSchema> = {
        plain_password: '',
        confirm_password: '',
    }

    const { register, setError, handleSubmit, formState, reset } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues,
    })

    const onFormSubmit = async (values: yup.InferType<typeof validationSchema>) => {
        try {
            setFail(undefined)
            setFetching(true)
            await client.resetPassword.reset({ password: values.plain_password, token: hash })
            onDone()
        } catch (e) {
            setFetching(false)
            const err = formatErrorDescription(
                e,
                formatMessage({ id: 'default.generic_form_error' }),
                Config.DEBUG.ACTIVE
            )
            setFail(err)

            if (isAxiosError(e) && e.response && isConstraintViolationList(e.response.data)) {
                e.response.data.violations.forEach((violation) => {
                    if (violation.propertyPath) {
                        // @ts-ignore
                        setError(violation.propertyPath, {
                            message: violation.message,
                        })
                    }
                })
            }
        }
        setFetching(false)
    }

    const onFormFailed = async (formErrors: FieldErrors<yup.InferType<typeof validationSchema>>) => {
        console.log('onFormFailed')
    }

    const handleFormSubmit = handleSubmit(onFormSubmit, onFormFailed)

    return (
        <Form
            className={classNames('auth-form', 'retrieve-password-form')}
            onSubmit={handleFormSubmit}
            onReset={() => reset()}
            noValidate
        >
            {fail && (!formState.errors || (formState.errors && Object.values(formState.errors).length === 0)) && (
                <Alert variant={'danger'} className="mb-4">
                    <p className="mb-0">{fail.error}</p>
                </Alert>
            )}
            <Form.Group className="form-group" controlId="registerPassword">
                <Form.Label className="required">
                    <FormattedMessage id="reset_my_password.field.new_password.label" />
                </Form.Label>
                <MaskedPassword
                    autoComplete="new-password"
                    {...register('plain_password', { required: true })}
                    isValid={isValidField('plain_password', formState)}
                    isInvalid={isInvalidField('plain_password', formState)}
                    showRules
                />
            </Form.Group>
            <Form.Group className="form-group" controlId="registerConfirmPassword">
                <Form.Label className="required">
                    <FormattedMessage id="reset_my_password.field.confirm_new_password.label" />
                </Form.Label>
                <MaskedPassword
                    autoComplete="new-password"
                    {...register('confirm_password', {
                        required: true,
                    })}
                    isValid={isValidField('confirm_password', formState)}
                    isInvalid={isInvalidField('confirm_password', formState)}
                />
                {formState.errors['confirm_password'] ? (
                    <Form.Control.Feedback type="invalid">
                        {formState.errors['confirm_password'].message}
                    </Form.Control.Feedback>
                ) : (
                    <></>
                )}
            </Form.Group>
            {/* Submit Button */}
            <div className="text-center">
                <Button variant="primary" type="submit" disabled={disabled} className="cta">
                    {fetching && (
                        <Spinner
                            as="span"
                            className="app-icon mr-1"
                            animation="grow"
                            size="sm"
                            variant="light"
                            aria-hidden="true"
                        />
                    )}
                    <span className="lbl">
                        <FormattedMessage id="reset_my_password.submit.label" />
                    </span>
                </Button>
            </div>
        </Form>
    )
}
