import { AxiosInstance } from 'axios'
import { IApiCustomerSalesmenResponse, ISalesmanModifyLoginParameters, ISalesmanService } from './types'
import { IApiMeResponse } from '../me/types'

const create = (instance: AxiosInstance): ISalesmanService => {
    /*****************************
     * METHODS
     *****************************/
    const get = (): Promise<IApiCustomerSalesmenResponse> => {
        return instance.get('/customer-salesmans')
    }

    const changePassword = (params: ISalesmanModifyLoginParameters): Promise<IApiMeResponse> => {
        return instance.put('/salesmen/me/change-password', params)
    }

    /*****************************
     * API RETURNS
     *****************************/
    return {
        getCustomerSalesmen: get,
        changePassword,
    }
}

export default {
    create,
}
