import * as yup from 'yup'
import { useIntl } from 'react-intl'
import { $PropertyType } from 'utility-types'

const passwordValidation = (formatMessage: $PropertyType<ReturnType<typeof useIntl>, 'formatMessage'>) =>
    yup
        .string()
        .required()
        .min(
            10,
            formatMessage({
                id: 'app.auth.register.password.global.error',
            })
        )
        .matches(
            /[0-9]/,
            formatMessage({
                id: 'app.auth.register.password.global.error',
            })
        )
        .matches(
            /[a-z]/,
            formatMessage({
                id: 'app.auth.register.password.global.error',
            })
        )
        .matches(
            /[A-Z]/,
            formatMessage({
                id: 'app.auth.register.password.global.error',
            })
        )
export { passwordValidation }
