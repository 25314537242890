import React from 'react'
import { Route, Switch } from 'react-router-dom'
import RedirectLanguage from '../components/RedirectLanguage/RedirectLanguage'
import ErrorPage from '../pages/ErrorPage'
import LoginPage from '../pages/LoginPage'
import MaintenancePage from '../pages/MaintenancePage'
import RestrictedCustomer from './Customer/Restricted'
import SalesmanArea from './Salesman/SalesmanArea'
import { getRouteMatch } from './utils'
import { createStructuredSelector } from 'reselect'
import { makeSelectAuthLoggedIn, makeSelectAuthMe } from '../store/auth/selectors'
import { useSelector } from 'react-redux'
import { IApplicationRootState } from '../store'
import { IMe } from '../services/api/service/me/types'
import { isSalesmanResource } from '../store/salesmens/utils'
import LogoutPage from '../pages/LogoutPage'
import LoggedPage from '../pages/LoggedPage'
import { makeSelectTreeMode } from '../store/config/selectors'
import { TreeMode } from '../services/api/service/classification/types'
import ResetPasswordPage from '../pages/ResetPasswordPage'
import ForgotPasswordPage from '../pages/ForgotPasswordPage'

const stateSelector = createStructuredSelector<any, any>({
    loggedIn: makeSelectAuthLoggedIn(),
    me: makeSelectAuthMe(),
    treeMode: makeSelectTreeMode(),
})

function Component(): JSX.Element {
    // récupération des informations de la personne connectée
    const { loggedIn, me, treeMode } = useSelector<
        IApplicationRootState,
        {
            loggedIn: boolean
            me?: IMe
            treeMode: TreeMode
        }
    >(stateSelector)

    return (
        <Switch>
            <Route exact path="/" component={RedirectLanguage} />
            <Route exact path={getRouteMatch('maintenance')} component={MaintenancePage} key={'maintenance'} />
            <Route exact path={getRouteMatch('error')} component={ErrorPage} key={'error'} />
            <Route exact path={getRouteMatch('login')} key={'login'} component={LoginPage} />
            <Route exact path={getRouteMatch('forgotPassword')} key={'resetPassword'} component={ForgotPasswordPage} />
            <Route exact path={getRouteMatch('resetPassword')} key={'resetPassword'} component={ResetPasswordPage} />
            <Route exact path={getRouteMatch('logged')} key={'logged'} component={LoggedPage} />
            <Route exact path={getRouteMatch('logout')} key={'logout'} component={LogoutPage} />
            {loggedIn && isSalesmanResource(me) && (
                <Route path={getRouteMatch('salesmanArea')} component={SalesmanArea} key={'salesmanArea'} />
            )}
            {loggedIn && <RestrictedCustomer treeMode={treeMode} />}
        </Switch>
    )
}

export default Component
