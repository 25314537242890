import React, { forwardRef, InputHTMLAttributes, useState } from 'react'
import { Form, FormControlProps, InputGroup } from 'react-bootstrap'
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'

type Props = Omit<FormControlProps, 'type'> &
    Pick<InputHTMLAttributes<HTMLInputElement>, 'autoComplete'> & {
        showRules?: boolean
    }

const MaskedPassword = forwardRef<HTMLInputElement, Props>(({ isInvalid, isValid, showRules, ...rest }, ref) => {
    const [showPassword, setShowPassword] = useState(false)
    return (
        <>
            <InputGroup
                className={classNames('mb-1', 'masked-password-field', {
                    'is-valid': isValid,
                    'is-invalid': isInvalid,
                })}
            >
                <Form.Control
                    ref={ref}
                    name="password"
                    isValid={isValid}
                    isInvalid={isInvalid}
                    {...rest}
                    type={showPassword ? 'text' : 'password'}
                />
                <InputGroup.Text onMouseDown={() => setShowPassword(true)} onMouseUp={() => setShowPassword(false)}>
                    <FontAwesomeIcon fixedWidth icon={showPassword ? faEyeSlash : faEye} className="icon app-icon" />
                </InputGroup.Text>
            </InputGroup>
            {showRules && (
                <div
                    className={classNames('text-muted', 'small', {
                        'help-field': !isInvalid && !isValid,
                        'invalid-feedback': isInvalid,
                        'valid-feedback': isValid,
                    })}
                >
                    <FormattedMessage id="app.auth.register.password.global.error" />
                </div>
            )}
        </>
    )
})

export default MaskedPassword
