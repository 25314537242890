import { createSelector } from 'reselect'
import { IApplicationRootState } from '../index'
import { initialState } from './reducer'
import { ContainerState, IAuthCheck2FaState, IAuthLoginState } from './types'

/**
 * Direct selector to the auth state domain
 */

const selectAuthDomain = (state: IApplicationRootState): ContainerState => {
    return state.auth || initialState
}

const selectAuthLoginDomain = (state: IApplicationRootState): IAuthLoginState => {
    return state.auth.login || initialState.login
}

const selectAuth2FaDomain = (state: IApplicationRootState): IAuthCheck2FaState => {
    return state.auth.check2fa || initialState.check2fa
}

const selectAuthChangePasswordDomain = (state: IApplicationRootState): IAuthCheck2FaState => {
    return state.auth.changePassword || initialState.changePassword
}

/**
 * Other specific selectors
 */

/**
 * Default selector used by Auth
 */

const makeSelectAuthMe = () => createSelector(selectAuthDomain, (globalState) => globalState.me)
const makeSelectAuthLoggedIn = () => createSelector(selectAuthDomain, (globalState) => globalState.logged)
const makeSelectAuthError = () => createSelector(selectAuthLoginDomain, (globalState) => globalState.error)
const makeSelectAuthFetching = () => createSelector(selectAuthLoginDomain, (globalState) => globalState.fetching)
const makeSelect2FaError = () => createSelector(selectAuth2FaDomain, (state) => state.error)
const makeSelect2FaMobilePhone = () => createSelector(selectAuth2FaDomain, (state) => state.mobilePhone)
const makeSelect2FaEmail = () => createSelector(selectAuth2FaDomain, (state) => state.email)
const makeSelect2FaFetching = () => createSelector(selectAuth2FaDomain, (state) => state.fetching)
const makeSelect2FaToken = () => createSelector(selectAuth2FaDomain, (state) => state.token)
const makeSelectRequire2Fa = () => createSelector(selectAuthDomain, (state) => state.require2fa)
const makeSelectRequireChangePassword = () => createSelector(selectAuthDomain, (state) => state.requireChangePassword)
const makeSelectChangePasswordFetching = () => createSelector(selectAuthChangePasswordDomain, (state) => state.fetching)
const makeSelectChangePasswordError = () => createSelector(selectAuthChangePasswordDomain, (state) => state.error)
const makeSelectChangePasswordToken = () => createSelector(selectAuthChangePasswordDomain, (state) => state.token)

export {
    selectAuthDomain,
    makeSelectAuthError,
    makeSelectAuthFetching,
    makeSelectAuthLoggedIn,
    makeSelectAuthMe,
    makeSelect2FaError,
    makeSelect2FaFetching,
    makeSelectRequire2Fa,
    makeSelect2FaMobilePhone,
    makeSelect2FaEmail,
    makeSelect2FaToken,
    makeSelectRequireChangePassword,
    makeSelectChangePasswordFetching,
    makeSelectChangePasswordError,
    makeSelectChangePasswordToken,
}
