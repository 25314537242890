import React, { useCallback, useState } from 'react'
import { Alert, Form, Spinner } from 'react-bootstrap'
import classNames from 'classnames'
import { FormattedMessage, useIntl } from 'react-intl'
import Button from '../../../components/Buttons/Button'
import { useHistory } from 'react-router-dom'
import { isInternetExplorer } from '../../../utils/browser'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { get, has } from 'lodash'
import { sleep } from '../../../utils/sleep'
import SweetAlert from 'react-bootstrap-sweetalert'
import client from '../../../services/api/client'
import { formatErrorDescription } from '../../../store/http/utils'
import Config from '../../../config'
import { IApiErrorDescription } from '../../../store/http/types'
import { isAxiosError, isConstraintViolationList } from '../../../services/api/utils'
import HttpStatusCode from '../../../store/http/codes'

type Props = {
    onDone: () => void
    hash: string
    mobilePhone?: string | null
    email?: string | null
}

export default function ({ onDone, hash, mobilePhone, email }: Props) {
    const history = useHistory()
    const { formatMessage, locale } = useIntl() //hook react-intl permettant de récupérer l'api FormattedMessage
    const [isProcessing, setIsProcessing] = useState(false)
    const isIe = isInternetExplorer()
    const [showError, setShowError] = useState<boolean>(false)
    const [showForm, setShowForm] = useState(false)
    const [globalError, setGlobalError] = useState<IApiErrorDescription | undefined>(undefined)

    const validationSchema = yup.object({
        code: yup.string().required(formatMessage({ id: 'login.code_required' })),
    })

    const {
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        reset,
        formState: { errors, isSubmitting },
    } = useForm<yup.InferType<typeof validationSchema>>({
        resolver: yupResolver(validationSchema),
    })

    const disabled = isSubmitting || isProcessing
    const isInvalid = has(errors, 'code')
    const fieldError = get(errors, 'code.message')
    const isFullFilled = getValues('code')?.length > 0

    const handleSendCode = useCallback(() => {
        setIsProcessing(true)
        client.resetPassword
            .send2fa(hash)
            .then(() => {
                setShowForm(true)
                setIsProcessing(false)
            })
            .catch((e) => {
                setShowError(true)
                setGlobalError(
                    formatErrorDescription(e, formatMessage({ id: 'default.generic_form_error' }), Config.DEBUG.ACTIVE)
                )
                setIsProcessing(false)
            })
    }, [formatMessage, hash])

    const handleShowErrorConfirm = useCallback((): void => {
        setShowError(false)
        sleep(250, () => {
            document.getElementById('reset-password-2fa-field')?.focus()
        })
    }, [setShowError])

    const onFormFailed = async (formErrors: typeof errors) => {
        // console.log('onFormFailed', formErrors)
    }

    // NOTE DEV: pas de useCallback car cela plante react-hook-form
    const onFormSubmit = async (data: { code: string }) => {
        try {
            setIsProcessing(true)
            await client.resetPassword.check2fa({ code: data.code, token: hash })
            setIsProcessing(false)
            onDone?.()
        } catch (e) {
            setIsProcessing(false)

            const err = formatErrorDescription(
                e,
                formatMessage({ id: 'default.generic_form_error' }),
                Config.DEBUG.ACTIVE
            )

            if (err.status === HttpStatusCode.BAD_REQUEST && !err.errors) {
                err.error = formatMessage({ id: 'reset_password.message.problem.invalid_2fa' })
            }

            setGlobalError(err)

            if (isAxiosError(e) && e.response && isConstraintViolationList(e.response.data)) {
                e.response.data.violations.forEach((violation) => {
                    if (violation.propertyPath) {
                        // @ts-ignore
                        setError(violation.propertyPath, {
                            message: violation.message,
                        })
                    }
                })
            }

            setShowError(true)
        }
    }

    const handleFormSubmit = handleSubmit(onFormSubmit, onFormFailed)

    return (
        <>
            {!showForm ? (
                <div className="mb-4 text-center alert alert-info">
                    <h6 className="bold text-uppercase alert-heading">
                        <FormattedMessage id="reset_my_password.2fa.title" />
                    </h6>
                    <p>
                        {mobilePhone && (
                            <FormattedMessage id="reset_my_password.2fa.sms.text" values={{ phone: mobilePhone }} />
                        )}
                        {email && <FormattedMessage id="reset_my_password.2fa.email.text" values={{ email }} />}
                    </p>
                    <Button
                        className={'btn-next'}
                        variant="primary"
                        disabled={disabled}
                        type="submit"
                        onClick={handleSendCode}
                    >
                        {isProcessing && (
                            <Spinner
                                as="span"
                                className="app-icon mr-1"
                                animation="grow"
                                size="sm"
                                variant="light"
                                aria-hidden="true"
                            />
                        )}
                        <FormattedMessage id={'reset_my_password.2fa.send.cta'} />
                    </Button>
                </div>
            ) : (
                <Form
                    noValidate
                    onSubmit={handleFormSubmit}
                    onReset={() => {
                        reset()
                    }}
                >
                    <Alert variant="success" className="mb-4">
                        <Alert.Heading>
                            <FormattedMessage id="reset_my_password.2fa.title" />
                        </Alert.Heading>
                        <p>
                            {mobilePhone && (
                                <FormattedMessage id={'login.2fa_info.sms'} values={{ mobile_phone: mobilePhone }} />
                            )}
                            {email && <FormattedMessage id={'login.2fa_info.email'} values={{ email }} />}
                        </p>
                    </Alert>
                    <Form.Group>
                        <div className={classNames('form-group-inner', { 'is-invalid': isInvalid })}>
                            <Form.Control
                                autoFocus
                                id="reset-password-2fa-field"
                                size="lg"
                                type="text"
                                autoComplete="off"
                                spellCheck={false}
                                autoCapitalize={'none'}
                                disabled={disabled}
                                isInvalid={isInvalid}
                                className={classNames({ 'is-invalid': isInvalid }, { 'has-value': isFullFilled })}
                                placeholder={isIe ? formatMessage({ id: 'placeholder.authenticate_code' }) : ''}
                                {...register('code', { required: true })}
                            />
                            {!isIe && (
                                <span className={'form-control-placeholder'}>
                                    <FormattedMessage id={'placeholder.authenticate_code'} />
                                </span>
                            )}
                        </div>
                        {isInvalid && <Form.Control.Feedback type="invalid">{fieldError}</Form.Control.Feedback>}
                    </Form.Group>

                    <div className={'login-form-step-footer'}>
                        <Button className={'btn-next'} variant="primary" disabled={disabled} type="submit">
                            {isProcessing && (
                                <Spinner
                                    as="span"
                                    className="app-icon mr-1"
                                    animation="grow"
                                    size="sm"
                                    variant="light"
                                    aria-hidden="true"
                                />
                            )}
                            <FormattedMessage id={'default.validate'} />
                        </Button>
                    </div>
                </Form>
            )}
            <SweetAlert
                customClass="swal-login-error"
                error
                title={formatMessage({
                    id: 'default.error',
                })}
                show={showError}
                onConfirm={handleShowErrorConfirm}
                openAnim={{ name: 'fadeInDown', duration: 500 }}
                closeAnim={{ name: 'fadeOutUp', duration: 500 }}
            >
                {globalError?.error}
            </SweetAlert>
        </>
    )
}
