/*
 *
 * Salesman actions
 *
 */

import { action } from 'typesafe-actions'

import ActionTypes from './constants'
import {
    ISalesmanModifyLoginProcessPayload,
    ISalesmenCustomersFailurePayload,
    ISalesmenCustomersProcessPayload,
    ISalesmenCustomersSuccessPayload,
    ISalesmenProspectEditFailurePayload,
    ISalesmenProspectEditProcessPayload,
    ISalesmenProspectEditSuccessPayload,
    ISalesmenProspectsFailurePayload,
    ISalesmenProspectsProcessPayload,
    ISalesmenProspectsRefreshPayload,
    ISalesmenProspectsSuccessPayload,
} from './types'
import { $PropertyType } from 'utility-types'
import { ICustomerModifyLoginParameters } from '../../services/api/service/customers/types'
import { AxiosError } from 'axios'

export const salesmenResetAction = () => action<ActionTypes.RESET_ACTION>(ActionTypes.RESET_ACTION)

export const salesmenCustomersProcessAction = (
    page: $PropertyType<ISalesmenCustomersProcessPayload, 'page'>,
    pageSize: $PropertyType<ISalesmenCustomersProcessPayload, 'pageSize'>,
    filters: $PropertyType<ISalesmenCustomersProcessPayload, 'filters'>,
    sortBy: $PropertyType<ISalesmenCustomersProcessPayload, 'sortBy'>
) =>
    action<ActionTypes.CUSTOMER_PROCESS_ACTION, ISalesmenCustomersProcessPayload>(ActionTypes.CUSTOMER_PROCESS_ACTION, {
        page,
        pageSize,
        filters,
        sortBy,
    })
export const salesmenCustomersSuccessAction = (response: $PropertyType<ISalesmenCustomersSuccessPayload, 'response'>) =>
    action<ActionTypes.CUSTOMER_SUCCESS_ACTION, ISalesmenCustomersSuccessPayload>(ActionTypes.CUSTOMER_SUCCESS_ACTION, {
        response,
    })
export const salesmenCustomersFailureAction = (error: $PropertyType<ISalesmenCustomersFailurePayload, 'error'>) =>
    action<ActionTypes.CUSTOMER_FAILURE_ACTION, ISalesmenCustomersFailurePayload>(ActionTypes.CUSTOMER_FAILURE_ACTION, {
        error,
    })

export const salesmenProspectsProcessAction = (
    page: $PropertyType<ISalesmenCustomersProcessPayload, 'page'>,
    pageSize: $PropertyType<ISalesmenCustomersProcessPayload, 'pageSize'>,
    filters: $PropertyType<ISalesmenCustomersProcessPayload, 'filters'>,
    sortBy: $PropertyType<ISalesmenCustomersProcessPayload, 'sortBy'>
) =>
    action<ActionTypes.PROSPECT_PROCESS_ACTION, ISalesmenProspectsProcessPayload>(ActionTypes.PROSPECT_PROCESS_ACTION, {
        page,
        pageSize,
        filters,
        sortBy,
    })
export const salesmenProspectsSuccessAction = (response: $PropertyType<ISalesmenProspectsSuccessPayload, 'response'>) =>
    action<ActionTypes.PROSPECT_SUCCESS_ACTION, ISalesmenProspectsSuccessPayload>(ActionTypes.PROSPECT_SUCCESS_ACTION, {
        response,
    })
export const salesmenProspectsFailureAction = (error: $PropertyType<ISalesmenProspectsFailurePayload, 'error'>) =>
    action<ActionTypes.PROSPECT_FAILURE_ACTION, ISalesmenProspectsFailurePayload>(ActionTypes.PROSPECT_FAILURE_ACTION, {
        error,
    })
export const salesmenProspectsRefreshAction = (page = 1) =>
    action<ActionTypes.PROSPECT_REFRESH_ACTION, ISalesmenProspectsRefreshPayload>(ActionTypes.PROSPECT_REFRESH_ACTION, {
        page,
    })

export const salesmenProspectEditProcessAction = (
    params: $PropertyType<ISalesmenProspectEditProcessPayload, 'params'>,
    identifier?: $PropertyType<ISalesmenProspectEditProcessPayload, 'identifier'>
) =>
    action<ActionTypes.PROSPECT_EDIT_PROCESS_ACTION, ISalesmenProspectEditProcessPayload>(
        ActionTypes.PROSPECT_EDIT_PROCESS_ACTION,
        {
            params,
            identifier,
        }
    )

export const salesmenProspectEditSuccessAction = (data: $PropertyType<ISalesmenProspectEditSuccessPayload, 'data'>) =>
    action<ActionTypes.PROSPECT_EDIT_SUCCESS_ACTION, ISalesmenProspectEditSuccessPayload>(
        ActionTypes.PROSPECT_EDIT_SUCCESS_ACTION,
        {
            data,
        }
    )
export const salesmenProspectEditFailureAction = (error: $PropertyType<ISalesmenProspectEditFailurePayload, 'error'>) =>
    action<ActionTypes.PROSPECT_EDIT_FAILURE_ACTION, ISalesmenProspectEditFailurePayload>(
        ActionTypes.PROSPECT_EDIT_FAILURE_ACTION,
        {
            error,
        }
    )

export const salesmenProspectEditResetAction = () =>
    action<ActionTypes.PROSPECT_EDIT_RESET_ACTION>(ActionTypes.PROSPECT_EDIT_RESET_ACTION)

export const salesmanModifyLoginProcessAction = (params: ICustomerModifyLoginParameters) =>
    action<ActionTypes.PROCESS_MODIFY_LOGIN_ACTION, ISalesmanModifyLoginProcessPayload>(
        ActionTypes.PROCESS_MODIFY_LOGIN_ACTION,
        {
            ...params,
        }
    )
export const salesmanModifyLoginSuccessAction = () =>
    action<ActionTypes.SUCCESS_MODIFY_LOGIN_ACTION>(ActionTypes.SUCCESS_MODIFY_LOGIN_ACTION)
export const salesmanModifyLoginFailureAction = (error: AxiosError) =>
    action<ActionTypes.FAILURE_MODIFY_LOGIN_ACTION, { error: AxiosError }>(ActionTypes.FAILURE_MODIFY_LOGIN_ACTION, {
        error,
    })
export const salesmanModifyLoginResetAction = () =>
    action<ActionTypes.RESET_MODIFY_LOGIN_ACTION>(ActionTypes.RESET_MODIFY_LOGIN_ACTION)
