import { AxiosInstance, AxiosResponse } from 'axios'
import {
    ApiResetPasswordGetTokenResponse,
    ApiResetResetPasswordCreateResponse,
    IResetPasswordService,
    ResetPasswordCheck2faParams,
    ResetPasswordCreateParams,
    ResetPasswordReResetParams,
} from './types'

const create = (instance: AxiosInstance): IResetPasswordService => {
    const create = (params: ResetPasswordCreateParams): Promise<ApiResetResetPasswordCreateResponse> => {
        return instance.post('/reset-password-requests', { username: params.username })
    }

    const get = (token: string): Promise<ApiResetPasswordGetTokenResponse> => {
        return instance.get(`/reset-password-requests/${token}`)
    }

    const send2fa = (token: string): Promise<AxiosResponse> => {
        return instance.put(`/reset-password-requests/${token}/2fa/send`)
    }

    const check2fa = (params: ResetPasswordCheck2faParams): Promise<AxiosResponse> => {
        return instance.put(`/reset-password-requests/${params.token}/2fa/check`, { code: params.code })
    }

    const reset = (params: ResetPasswordReResetParams): Promise<AxiosResponse> => {
        return instance.put(`/reset-password-requests/${params.token}`, { password: params.password })
    }

    return {
        create,
        get,
        send2fa,
        check2fa,
        reset,
    }
}

export default {
    create,
}
