/*
 *
 * CustomerDashboard
 *
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { Alert, Card, Col, Form, FormGroup, InputGroup, Row } from 'react-bootstrap'
import SweetAlert from 'react-bootstrap-sweetalert/dist'
import { useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import * as yup from 'yup'
import Button from '../../components/Buttons/Button'
import { ICustomerModifyLoginParameters } from '../../services/api/service/customers/types'
import { customerModifyLoginProcessAction, customerModifyLoginResetAction } from '../../store/customers/actions'
import {
    makeSelectCustomerModifyLoginError,
    makeSelectCustomerModifyLoginFetching,
    makeSelectCustomerModifyLoginSuccess,
} from '../../store/customers/selectors'
import ApplicationHelper from '../../utils/applicationHelper'
import { makeSelectAuthMe } from '../../store/auth/selectors'
import { isSalesmanResource } from '../../store/salesmens/utils'
import { yupResolver } from '@hookform/resolvers/yup'
import { isAxiosError, isConstraintViolationList } from '../../services/api/utils'
import { IApiErrorDescription } from '../../store/http/types'
import { formatErrorDescription } from '../../store/http/utils'
import Config from '../../config'
import { isCustomerResource } from '../../store/customers/utils'
import {
    makeSelectSalesmanModifyLoginError,
    makeSelectSalesmanModifyLoginFetching,
    makeSelectSalesmanModifyLoginSuccess,
} from '../../store/salesmens/selectors'
import { salesmanModifyLoginProcessAction, salesmanModifyLoginResetAction } from '../../store/salesmens/actions'
import { ISalesmanModifyLoginParameters } from '../../services/api/service/salesman/types'

interface IProps {}

const customerStateSelector = createStructuredSelector<any, any>({
    fetching: makeSelectCustomerModifyLoginFetching(),
    done: makeSelectCustomerModifyLoginSuccess(),
    error: makeSelectCustomerModifyLoginError(),
})
const salesmanStateSelector = createStructuredSelector<any, any>({
    fetching: makeSelectSalesmanModifyLoginFetching(),
    done: makeSelectSalesmanModifyLoginSuccess(),
    error: makeSelectSalesmanModifyLoginError(),
})
const meSelector = makeSelectAuthMe()

function CustomerLogin(): JSX.Element {
    const dispatch = useDispatch()
    const { formatMessage } = useIntl()
    const me = useSelector(meSelector)
    const isCustomer = isCustomerResource(me)
    const { error, fetching, done } = useSelector(isCustomer ? customerStateSelector : salesmanStateSelector)
    const [globalError, setGlobalError] = useState<IApiErrorDescription | undefined>(undefined)
    const [successShown, setSuccessShown] = useState<boolean>(false)
    const [isRevealOldPassword, setIsRevealOldPassword] = useState<boolean>(false)
    const [isRevealNewPassword, setIsRevealNewPassword] = useState<boolean>(false)

    // ATENTION: On a découpé en step le login. De ce fait, on aussi des contraintes dans chaque step
    const validationSchema = yup.object().shape({
        old_password: yup.string().required(formatMessage({ id: 'modify_login.current_password_required' })),
        new_password: yup.string().required(formatMessage({ id: 'modify_login.new_password_required' })),
    })

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        reset,
    } = useForm<{ old_password: string; new_password: string }>({
        resolver: yupResolver(validationSchema),
    })

    const onSubmit = (data: ICustomerModifyLoginParameters | ISalesmanModifyLoginParameters): void => {
        dispatch(isCustomer ? customerModifyLoginProcessAction(data) : salesmanModifyLoginProcessAction(data))
        setGlobalError(undefined)
        setSuccessShown(false)
    }

    const handleOldPasswordMouseDown = () => {
        if (ApplicationHelper.isTouchScreen()) {
            return
        }
        setIsRevealOldPassword(true)
    }
    const handleOldPasswordMouseUp = () => {
        if (ApplicationHelper.isTouchScreen()) {
            return
        }
        setIsRevealOldPassword(false)
    }
    const handleOldPasswordClick = () => {
        if (!ApplicationHelper.isTouchScreen()) {
            return
        }
        setIsRevealOldPassword((state) => !state)
    }

    const handleNewPasswordMouseDown = () => {
        if (ApplicationHelper.isTouchScreen()) {
            return
        }
        setIsRevealNewPassword(true)
    }
    const handleNewPasswordMouseUp = () => {
        if (ApplicationHelper.isTouchScreen()) {
            return
        }
        setIsRevealNewPassword(false)
    }
    const handleNewPasswordClick = () => {
        if (!ApplicationHelper.isTouchScreen()) {
            return
        }
        setIsRevealNewPassword((state) => !state)
    }

    useEffect(() => {
        if (typeof error === 'undefined') {
            return
        }

        setGlobalError(
            formatErrorDescription(error, formatMessage({ id: 'default.generic_form_error' }), Config.DEBUG.ACTIVE)
        )

        // console.log(isAxiosError(error), error.response, isConstraintViolationList(error.response.data))

        if (isAxiosError(error) && error.response && isConstraintViolationList(error.response.data)) {
            const formattedErrors = {}
            error.response.data.violations.forEach((violation) => {
                if (violation.propertyPath) {
                    formattedErrors[violation.propertyPath] = [
                        ...(formattedErrors[violation.propertyPath] || []),
                        violation.message,
                    ]
                }
            })

            for (const propertyPath in formattedErrors) {
                // @ts-ignore
                setError(propertyPath, {
                    message: formattedErrors[propertyPath].join('\n'),
                })
            }
        }
    }, [error, formatMessage, setError, setGlobalError])

    return (
        <>
            <Card className={classNames('member-card')}>
                <Card.Header>
                    <h3 className={'hv'}>
                        <FormattedMessage
                            id={isSalesmanResource(me) ? 'modify_login.update_login' : 'modify_login.update_my_login'}
                        />
                    </h3>
                </Card.Header>
                <Card.Body>
                    <div className={'modify-login-section'}>
                        <Row>
                            <Col xs={24} md={12}>
                                <div className={'modify-login-form'}>
                                    {globalError && globalError.error && (
                                        <Alert variant={'danger'}>
                                            <p className="mb-0">{globalError.error}</p>
                                        </Alert>
                                    )}
                                    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                                        <FormGroup>
                                            <InputGroup className={errors.old_password ? 'is-invalid' : ''}>
                                                <Form.Control
                                                    type={isRevealOldPassword ? 'text' : 'password'}
                                                    placeholder={formatMessage({
                                                        id: 'placeholder.actual_password',
                                                        defaultMessage: 'mot de passe',
                                                    })}
                                                    autoComplete="current-password"
                                                    isInvalid={!!errors.old_password}
                                                    className={errors.old_password ? 'is-invalid' : ''}
                                                    {...register(`old_password`, { required: false })}
                                                />
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text
                                                        onMouseDown={handleOldPasswordMouseDown}
                                                        onMouseUp={handleOldPasswordMouseUp}
                                                        onClick={handleOldPasswordClick}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={
                                                                isRevealOldPassword
                                                                    ? ['fal', 'eye-slash']
                                                                    : ['fal', 'eye']
                                                            }
                                                        />
                                                    </InputGroup.Text>
                                                </InputGroup.Prepend>
                                                {errors.old_password && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {(errors.old_password.message as string)!
                                                            .split(`\n`)
                                                            .map((mesg: string) => (
                                                                <span className={'d-block mb-1'} key={mesg}>
                                                                    {mesg}
                                                                </span>
                                                            ))}
                                                    </Form.Control.Feedback>
                                                )}
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup>
                                            <InputGroup className={errors.new_password ? 'is-invalid' : ''}>
                                                <Form.Control
                                                    type={isRevealNewPassword ? 'text' : 'password'}
                                                    placeholder={formatMessage({
                                                        id: 'placeholder.new_password',
                                                    })}
                                                    autoComplete="current-password"
                                                    isInvalid={!!errors.new_password}
                                                    className={errors.new_password ? 'is-invalid' : ''}
                                                    {...register(`new_password`, { required: false })}
                                                />
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text
                                                        onMouseDown={handleNewPasswordMouseDown}
                                                        onMouseUp={handleNewPasswordMouseUp}
                                                        onClick={handleNewPasswordClick}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={
                                                                isRevealNewPassword
                                                                    ? ['fal', 'eye-slash']
                                                                    : ['fal', 'eye']
                                                            }
                                                        />
                                                    </InputGroup.Text>
                                                </InputGroup.Prepend>
                                                {errors.new_password && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {(errors.new_password.message as string)!
                                                            .split(`\n`)
                                                            .map((mesg: string) => (
                                                                <span className={'d-block mb-1'} key={mesg}>
                                                                    {mesg}
                                                                </span>
                                                            ))}
                                                    </Form.Control.Feedback>
                                                )}
                                            </InputGroup>
                                        </FormGroup>

                                        <Button type="submit" variant="primary" loading={fetching} block>
                                            <FormattedMessage id={'default.save_changes'} />
                                        </Button>
                                    </Form>
                                </div>
                            </Col>
                            <Col xs={24} md={12}>
                                <Card className={classNames('helper-card')}>
                                    <Card.Header>
                                        <Card.Title>
                                            <FontAwesomeIcon
                                                icon={['fal', 'exclamation-circle']}
                                                className={'app-icon'}
                                            />
                                            <FormattedMessage id={'modify_login.helper_title'} />
                                        </Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <p>
                                            <FormattedMessage id={'modify_login.helper_rules_explain'} />
                                        </p>
                                        <ul>
                                            <li>
                                                <FormattedMessage
                                                    id={'modify_login.helper_rule_1'}
                                                    values={{
                                                        count: 10,
                                                        strong: (chunks: Array<string>) => <strong>{chunks}</strong>,
                                                    }}
                                                />
                                            </li>
                                            <li>
                                                <FormattedMessage
                                                    id={'modify_login.helper_rule_2'}
                                                    values={{
                                                        lowercase: 1,
                                                        uppercase: 1,
                                                        strong: (chunks: Array<string>) => <strong>{chunks}</strong>,
                                                    }}
                                                />
                                            </li>
                                            <li>
                                                <FormattedMessage
                                                    id={'modify_login.helper_rule_3'}
                                                    values={{
                                                        count: 1,
                                                        strong: (chunks: Array<string>) => <strong>{chunks}</strong>,
                                                    }}
                                                />
                                            </li>
                                            <li>
                                                <FormattedMessage
                                                    id={'modify_login.helper_rule_4'}
                                                    values={{
                                                        count: 1,
                                                        strong: (chunks: Array<string>) => <strong>{chunks}</strong>,
                                                    }}
                                                />
                                            </li>
                                        </ul>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Card.Body>
            </Card>
            {done && (
                <SweetAlert
                    success
                    title={formatMessage({
                        id: 'modify_login.update_login',
                    })}
                    show={done && !successShown}
                    onConfirm={() => {
                        setSuccessShown(true)
                        dispatch(isCustomer ? customerModifyLoginResetAction() : salesmanModifyLoginResetAction())
                        reset()
                    }}
                >
                    <FormattedMessage id={'modify_login.success_message'} />
                </SweetAlert>
            )}
        </>
    )
}

CustomerLogin.defaultProps = {} as Partial<IProps>

export default CustomerLogin
