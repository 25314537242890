import React, { FunctionComponent, memo, useCallback, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { generatePath, NavLink, useParams } from 'react-router-dom'
import Config from '../../config'
import Logo from '../../assets/svg/logo-u10.svg'
import LoginWindowFooter from '../LoginWindow/LoginWindowFooter'
import { Alert, Spinner } from 'react-bootstrap'
import { getPath } from '../../routes'
import isString from 'lodash/isString'
import ResetPassword2FAStep from './Step/ResetPassword2FAStep'
import ResetPasswordReDefineStep from './Step/ResetPasswordReDefineStep'
import client from '../../services/api/client'
import { ResetPasswordGetTokenResponse } from '../../services/api/service/resetPasswordRequest/types'
import { IApiErrorDescription } from '../../store/http/types'
import { formatErrorDescription } from '../../store/http/utils'
import HttpStatusCode from '../../store/http/codes'
import SweetAlert from 'react-bootstrap-sweetalert'
import { sleep } from '../../utils/sleep'

const ResetPasswordWindow: FunctionComponent = (): JSX.Element => {
    const { token: hash } = useParams<{ token: string }>()
    const isHashInvalid = !hash || !isString(hash) || !hash.length
    const [globalError, setGlobalError] = useState<IApiErrorDescription | undefined>(undefined)
    const { locale, formatMessage } = useIntl() //hook react-intl permettant de récupérer l'api FormattedMessage
    const [resetRequest, setResetRequest] = useState<ResetPasswordGetTokenResponse | undefined>()
    const [isFetchingResetRequest, setIsFetchingResetRequest] = useState(true)
    const [isCheck2FA, setIsCheck2FA] = useState(false)
    const [showDoneMessage, setShowDoneMessage] = useState(false)

    const handleShowDoneConfirmClick = useCallback(() => {
        setShowDoneMessage(false)
        sleep(800, () => {
            // RELOAD
            window.location.href = generatePath(getPath('login', locale), { lang: locale })
        })
    }, [locale])

    const handleDefinePasswordsDone = useCallback(() => {
        setShowDoneMessage(true)
    }, [])

    const handle2FaDone = useCallback(() => {
        setIsCheck2FA(false)
    }, [setIsCheck2FA])

    useEffect(() => {
        if (isHashInvalid) {
            return
        }

        setIsFetchingResetRequest(true)
        client.resetPassword
            .get(hash)
            .then((response) => {
                setIsFetchingResetRequest(false)
                setResetRequest(response.data)
                setIsCheck2FA(response.data.is_2fa_needed)
            })
            .catch((e) => {
                setIsFetchingResetRequest(false)
                setResetRequest(undefined)
                setIsCheck2FA(false)

                const err = formatErrorDescription(
                    e,
                    formatMessage({ id: 'default.generic_form_error' }),
                    Config.DEBUG.ACTIVE
                )

                if (err.status === HttpStatusCode.NOT_FOUND) {
                    err.error = formatMessage({ id: 'reset_password.message.problem.link_expired' })
                }

                setGlobalError(err)
            })
    }, [isHashInvalid, hash, formatMessage])

    // step 1, récupération
    return (
        <div className={'login-window size-lg'}>
            <div className="login-window-body">
                <div className={'login-window-body-heading'}>
                    <div className="heading">
                        <img src={Logo} alt={Config.APP_NAME} />
                        <h1>
                            <FormattedMessage id={'reset_my_password.change_password.title'} />
                        </h1>
                        <p>
                            <FormattedMessage id={'reset_my_password.change_password.text'} />
                        </p>
                    </div>
                </div>
                {isHashInvalid ? (
                    <div className="callout callout-error">
                        <Alert.Heading>
                            <FormattedMessage id="default.error" />
                        </Alert.Heading>
                        <p className="mb-0">
                            <FormattedMessage id="reset_password.message.problem.handle" />
                        </p>
                        <NavLink to={generatePath(getPath('forgotPassword', locale), { lang: locale })}>
                            <FormattedMessage id="default.go_back" />
                        </NavLink>
                    </div>
                ) : (
                    <>
                        {isFetchingResetRequest ? (
                            <div className="login-loader">
                                <Spinner
                                    as="span"
                                    animation={'border'}
                                    variant={'light'}
                                    role={'loading'}
                                    aria-hidden="true"
                                />
                                <p>
                                    <FormattedMessage id="default.loading" />
                                </p>
                            </div>
                        ) : (
                            <>
                                {globalError ? (
                                    <div className="callout callout-error">
                                        <Alert.Heading>
                                            <FormattedMessage id="default.error" />
                                        </Alert.Heading>
                                        <p className="mb-0">{globalError.error}</p>
                                        <NavLink to={generatePath(getPath('forgotPassword', locale), { lang: locale })}>
                                            <FormattedMessage id="default.go_back" />
                                        </NavLink>
                                    </div>
                                ) : (
                                    <>
                                        {isCheck2FA ? (
                                            <ResetPassword2FAStep
                                                hash={hash}
                                                onDone={handle2FaDone}
                                                mobilePhone={resetRequest?.phone_number_2fa}
                                                email={resetRequest?.email_2fa}
                                            />
                                        ) : (
                                            <ResetPasswordReDefineStep onDone={handleDefinePasswordsDone} hash={hash} />
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
            <LoginWindowFooter />
            <SweetAlert
                customClass="swal-login-success"
                success
                title={formatMessage({
                    id: 'reset_my_password.field.success.title',
                })}
                show={showDoneMessage}
                onConfirm={handleShowDoneConfirmClick}
                openAnim={{ name: 'fadeInDown', duration: 500 }}
                closeAnim={{ name: 'fadeOutUp', duration: 500 }}
            >
                <FormattedMessage id="reset_my_password.field.success.message" />
            </SweetAlert>
        </div>
    )
}

export default memo(ResetPasswordWindow)
