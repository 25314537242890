import classNames from 'classnames'
import React from 'react'
import Helmet from 'react-helmet'
import { useIntl } from 'react-intl'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import Config from '../config'
import { isInternetExplorer } from '../utils/browser'
import ResetPasswordWindow from '../containers/ResetPasswordWindow/ResetPasswordWindow'
import { isLoggedIn } from '../services/api/service/authenticate/jwt'

const locales: Array<string> = Config.I18N.LANGUAGES

function ResetPasswordPage(props: RouteComponentProps): JSX.Element {
    const { formatMessage } = useIntl()

    if (isLoggedIn()) {
        return <Redirect to={'/'} />
    }

    return (
        <div className={classNames('login-window-wrapper', { 'is-ie': isInternetExplorer() })}>
            <Helmet>
                <title>{formatMessage({ id: 'reset_my_password.change_password.title' })}</title>
                <meta name="description" content={formatMessage({ id: 'reset_my_password.change_password.text' })} />
                <body className={'login-page reset-password'} />
            </Helmet>
            <ResetPasswordWindow />
        </div>
    )
}

export default ResetPasswordPage
