import { Col, Row } from 'react-bootstrap'
import LangSwitcher from '../LanguageProvider/LangSwitcher'
import { LangItemPartType } from '../../components/LangItem/types'
import Button from '../../components/Buttons/Button'
import { FormattedMessage } from 'react-intl'
import AssistanceModal from '../Assistance/AssistanceModal'
import React, { useCallback, useState } from 'react'

export default function () {
    const [helpShown, setHelpShown] = useState<boolean>(false)
    const handleHelpHide = useCallback(() => setHelpShown(false), [setHelpShown])
    const handleNeedHelpButtonClick = useCallback(() => setHelpShown(true), [setHelpShown])

    return (
        <>
            <div className={'login-window-footer'}>
                <Row>
                    <Col className={'col-login-window-lang'}>
                        <LangSwitcher
                            itemParts={[LangItemPartType.Flag, LangItemPartType.Native, LangItemPartType.Name]}
                        />
                    </Col>
                    <Col className={'col-login-window-help'}>
                        <Button variant={'link'} onClick={handleNeedHelpButtonClick}>
                            <FormattedMessage id={'default.need_help'} />
                        </Button>
                    </Col>
                </Row>
            </div>
            <AssistanceModal show={helpShown} onHide={handleHelpHide} onExit={handleHelpHide} />
        </>
    )
}
