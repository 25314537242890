import Config from '../config'
import { CollectionMap } from '../types/common'
import { IRoute } from './types'

const locales = Config.I18N.LANGUAGES.join('|')

const routes: CollectionMap<IRoute> = {
    login: {
        match: `/:lang(${locales})/(connexion|login)`,
        path: {
            fr: `/:lang(${locales})/connexion`,
            en: `/:lang(${locales})/login`,
        },
    },
    forgotPassword: {
        match: `/:lang(${locales})/forgot-password`,
        path: `/:lang(${locales})/forgot-password`,
    },
    resetPassword: {
        match: `/:lang(${locales})/reset-password/:token([A-Z0-9a-z-]+)?`,
        path: `/:lang(${locales})/reset-password/:token([A-Z0-9a-z-]+)?`,
    },
    home: {
        match: `/:lang(${locales})`,
        path: `/:lang(${locales})`,
    },
    logged: {
        match: `/:lang(${locales})/logged`,
        path: `/:lang(${locales})/logged`,
    },
    logout: {
        match: `/:lang(${locales})/logout`,
        path: `/:lang(${locales})/logout`,
    },
    error: {
        match: `/:lang(${locales})/error/:errorCode([0-9]{3})`,
        path: `/:lang(${locales})/error/:errorCode([0-9]{3})`,
    },
    cart: {
        match: `/:lang(${locales})/cart`,
        path: `/:lang(${locales})/cart`,
    },
    confirmation: {
        // eslint-disable-next-line no-useless-escape
        match: `/:lang(${locales})/cart/confirmation/:shippingLocationId(\/shipping-locations\/[A-Z0-9a-z\-]+)?`,
        // eslint-disable-next-line no-useless-escape
        path: `/:lang(${locales})/cart/confirmation/:shippingLocationId(\/shipping-locations\/[A-Z0-9a-z\-]+)?`,
    },
    maintenance: {
        match: `/:lang(${locales})/maintenance`,
        path: `/:lang(${locales})/maintenance`,
    },
    favorite: {
        match: `/:lang(${locales})/(favorites|favoris)/:fullSlug?`,
        path: {
            en: `/:lang(${locales})/favorites/:fullSlug?`,
            fr: `/:lang(${locales})/favoris/:fullSlug?`,
        },
    },
    bestSellers: {
        match: `/:lang(${locales})/best-sellers/:fullSlug?`,
        path: {
            en: `/:lang(${locales})/best-sellers/:fullSlug?`,
            fr: `/:lang(${locales})/best-sellers/:fullSlug?`,
        },
    },
    freshProducts: {
        match: `/:lang(${locales})/(nouveautes|new-releases)/:fullSlug?`,
        path: {
            en: `/:lang(${locales})/new-releases/:fullSlug?`,
            fr: `/:lang(${locales})/nouveautes/:fullSlug?`,
        },
    },
    myListing: {
        match: `/:lang(${locales})/(referencement-centrale|my-listing)/:fullSlug?`,
        path: {
            en: `/:lang(${locales})/my-listing/:fullSlug?`,
            fr: `/:lang(${locales})/referencement-centrale/:fullSlug?`,
        },
    },
    arrivalStocks: {
        match: `/:lang(${locales})/(mes-arrivages|my-arrivals)/:fullSlug?`,
        path: {
            en: `/:lang(${locales})/my-arrivals/:fullSlug?`,
            fr: `/:lang(${locales})/mes-arrivages/:fullSlug?`,
        },
    },
    discounts: {
        match: `/:lang(${locales})/(promotions|discounts)/:fullSlug?`,
        path: {
            en: `/:lang(${locales})/discounts/:fullSlug?`,
            fr: `/:lang(${locales})/promotions/:fullSlug?`,
        },
    },
    planograms: {
        match: `/:lang(${locales})/(planograms|planogrammes)/:fullSlug?`,
        path: {
            en: `/:lang(${locales})/planograms/:fullSlug?`,
            fr: `/:lang(${locales})/planogrammes/:fullSlug?`,
        },
    },
    // a garder en synchro avec store/planogram/utils.ts => PLANOGRAM_PATHNAME_REGEXP
    planogram: {
        match: `([A-Za-z0-9-_/]*)/planograms/:planogramId([\\w]{8}-[\\w]{4}-[\\w]{4}-[\\w]{4}-[\\w]{12})`,
        path: `([A-Za-z0-9-_/]*)/planograms/:planogramId([\\w]{8}-[\\w]{4}-[\\w]{4}-[\\w]{4}-[\\w]{12})`,
    },
    // a garder en synchro avec store/products/utils.ts => PRODUCT_PATHNAME_REGEXP
    product: {
        match: `([A-Za-z0-9-_/]*)/p/:productId([A-Za-z0-9-]+)`,
        path: `([A-Za-z0-9-_/]*)/p/:productId([A-Za-z0-9-]+)`,
    },
    category: {
        match: `/:lang(${locales})/categories/:categorySlug?`,
        path: {
            en: `/:lang(${locales})/categories/:categorySlug?`,
            fr: `/:lang(${locales})/categories/:categorySlug?`,
        },
    },
    catalog: {
        match: `/:lang(${locales})/(catalog|catalogue)/:fullSlug?`,
        path: {
            en: `/:lang(${locales})/catalog/:fullSlug?`,
            fr: `/:lang(${locales})/catalogue/:fullSlug?`,
        },
    },
    // grilles publiques
    productGridPublic: {
        match: `/:lang(${locales})/(product-grids|grille-produits)/details/:id`,
        path: {
            en: `/:lang(${locales})/product-grids/details/:id`,
            fr: `/:lang(${locales})/grille-produits/details/:id`,
        },
    },
    // grilles publiques
    productGridPublics: {
        match: `/:lang(${locales})/(product-grids|grille-produits)/:fullSlug?`,
        path: {
            en: `/:lang(${locales})/product-grids/:fullSlug?`,
            fr: `/:lang(${locales})/grille-produits/:fullSlug?`,
        },
    },
    customerArea: {
        match: `/:lang(${locales})/customer-area`,
        path: `/:lang(${locales})/customer-area`,
    },
    customerDashboard: {
        match: `/:lang(${locales})/customer-area/dashboard`,
        path: `/:lang(${locales})/customer-area/dashboard`,
    },
    customerOrders: {
        match: `/:lang(${locales})/customer-area/orders`,
        path: `/:lang(${locales})/customer-area/orders`,
    },
    customerAssets: {
        match: `/:lang(${locales})/customer-area/assets`,
        path: `/:lang(${locales})/customer-area/assets`,
    },
    customerOrder: {
        match: `/:lang(${locales})/customer-area/orders/:orderId`,
        path: `/:lang(${locales})/customer-area/orders/:orderId`,
    },
    customerCarts: {
        match: `/:lang(${locales})/customer-area/carts`,
        path: `/:lang(${locales})/customer-area/carts`,
    },
    customerInvoices: {
        match: `/:lang(${locales})/customer-area/invoices`,
        path: `/:lang(${locales})/customer-area/invoices`,
    },
    customerModifyLogin: {
        match: `/:lang(${locales})/customer-area/modify-login`,
        path: `/:lang(${locales})/customer-area/modify-login`,
    },
    customerExports: {
        match: `/:lang(${locales})/customer-area/exports`,
        path: `/:lang(${locales})/customer-area/exports`,
    },
    customerExport: {
        match: `/:lang(${locales})/customer-area/exports/:exportProductId`,
        path: `/:lang(${locales})/customer-area/exports/:exportProductId`,
    },
    customerImports: {
        match: `/:lang(${locales})/customer-area/imports/:type([A-Za-z_]+)?`,
        path: `/:lang(${locales})/customer-area/imports/:type([A-Za-z_]+)?`,
    },
    customerImport: {
        match: `/:lang(${locales})/customer-area/imports/report:id([\\/A-Za-z0-9-]+)?`,
        path: `/:lang(${locales})/customer-area/imports/report:id([\\/A-Za-z0-9-]+)?`,
    },
    customerStores: {
        match: `/:lang(${locales})/customer-area/stores`,
        path: `/:lang(${locales})/customer-area/stores`,
    },
    customerSalesmen: {
        match: `/:lang(${locales})/customer-area/salesmen`,
        path: `/:lang(${locales})/customer-area/salesmen`,
    },
    customerPictureExports: {
        match: `/:lang(${locales})/customer-area/picture-exports`,
        path: `/:lang(${locales})/customer-area/picture-exports`,
    },
    cmsPage: {
        match: `/:lang(${locales})/:slug([A-Za-z0-9-]+)`,
        path: `/:lang(${locales})/:slug([A-Za-z0-9-]+)`,
    },
    cmsPagePreview: {
        match: `/:lang(${locales})/cms-pages/preview/:cmsPageId`,
        path: `/:lang(${locales})/cms-pages/preview/:cmsPageId`,
    },
    salesmanArea: {
        match: `/:lang(${locales})/salesman-area`,
        path: `/:lang(${locales})/salesman-area`,
    },
    salesmanDashboard: {
        match: `/:lang(${locales})/salesman-area/dashboard`,
        path: `/:lang(${locales})/salesman-area/dashboard`,
    },
    salesmanCustomers: {
        match: `/:lang(${locales})/salesman-area/customers`,
        path: `/:lang(${locales})/salesman-area/customers`,
    },
    salesmanOrders: {
        match: `/:lang(${locales})/salesman-area/orders`,
        path: `/:lang(${locales})/salesman-area/orders`,
    },
    salesmanAssets: {
        match: `/:lang(${locales})/salesman-area/assets`,
        path: `/:lang(${locales})/salesman-area/assets`,
    },
    salesmanOrder: {
        match: `/:lang(${locales})/salesman-area/orders/:orderId`,
        path: `/:lang(${locales})/salesman-area/orders/:orderId`,
    },
    salesmanAsset: {
        match: `/:lang(${locales})/salesman-area/assets/:orderId`,
        path: `/:lang(${locales})/salesman-area/assets/:orderId`,
    },
    salesmanProspects: {
        match: `/:lang(${locales})/salesman-area/customers/prospects`,
        path: `/:lang(${locales})/salesman-area/customers/prospects`,
    },

    salesmanCarts: {
        match: `/:lang(${locales})/salesman-area/carts`,
        path: `/:lang(${locales})/salesman-area/carts`,
    },
    salesmanInvoices: {
        match: `/:lang(${locales})/salesman-area/invoices`,
        path: `/:lang(${locales})/salesman-area/invoices`,
    },
    salesmanModifyLogin: {
        match: `/:lang(${locales})/salesman-area/modify-login`,
        path: `/:lang(${locales})/salesman-area/modify-login`,
    },
}

export default routes
