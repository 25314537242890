import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useState, forwardRef } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import * as yup from 'yup'
import Button from '../../../components/Buttons/Button'
import ApplicationHelper from '../../../utils/applicationHelper'
import { isInternetExplorer } from '../../../utils/browser'
import { ILoginPasswordStepFormData, ILoginStepProps } from '../types'
import { generatePath, NavLink } from 'react-router-dom'
import { getPath } from '../../../routes'

type IProps = ILoginStepProps<ILoginPasswordStepFormData>

const PasswordFormStep = forwardRef<HTMLInputElement, IProps>(
    ({
        register,
        previousButtonText,
        nextButtonText,
        onValidated,
        disabled = false,
        fetching = false,
        step,
        onPreviousButtonClick,
    }) => {
        const [currentValue, setCurrentValue] = useState<string>('')
        const [fieldError, setFieldError] = useState<string>('')
        const [isRevealPassword, setIsRevealPassword] = useState<boolean>(false)
        const isFullFilled = currentValue.length > 0
        const isInvalid = fieldError.length > 0
        const isIe = isInternetExplorer()
        const { formatMessage, locale } = useIntl()

        const constraints = yup.object().shape({
            password: yup.string().required(formatMessage({ id: 'login.password_required' })),
        })

        const handleOnPreviousButtonClick = () => {
            if (onPreviousButtonClick) {
                onPreviousButtonClick(step)
            }
        }

        const validateField = (value: string) => {
            const val = {
                password: value,
            }
            constraints
                .validate(val)
                .catch((err) => {
                    setFieldError(err.message)
                })
                .then((valid) => {
                    if (valid) {
                        setFieldError('')
                        onValidated(valid as ILoginPasswordStepFormData, step)
                    }
                })
        }

        const handleOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
                validateField(currentValue)
            }
        }

        const handleOnButtonClick = () => {
            validateField(currentValue)
        }

        const handleOnFieldValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            setCurrentValue(e.target.value)
        }

        const handlePasswordMouseDown = () => {
            if (ApplicationHelper.isTouchScreen()) {
                return
            }
            setIsRevealPassword(true)
        }

        const handlePasswordMouseUp = () => {
            if (ApplicationHelper.isTouchScreen()) {
                return
            }
            setIsRevealPassword(false)
        }
        const handlePasswordClick = () => {
            if (!ApplicationHelper.isTouchScreen()) {
                return
            }
            setIsRevealPassword((state) => !state)
        }

        return (
            <div
                className={classNames(
                    'login-form-step',
                    'login-form-step-2',
                    { 'is-invalid': isInvalid },
                    { 'is-full-filled': isFullFilled }
                )}
            >
                <Form.Group>
                    <div className={classNames('form-group-inner', { 'is-invalid': isInvalid })}>
                        <InputGroup>
                            <Form.Control
                                size="lg"
                                type={isRevealPassword ? 'text' : 'password'}
                                autoComplete="off"
                                spellCheck={false}
                                autoCapitalize={'none'}
                                isInvalid={isInvalid}
                                onKeyPress={handleOnKeyPress}
                                disabled={disabled}
                                className={classNames({ 'is-invalid': isInvalid }, { 'has-value': isFullFilled })}
                                placeholder={isIe ? formatMessage({ id: 'placeholder.password' }) : ''}
                                {...register('password', { required: true, onChange: handleOnFieldValueChange })}
                            />
                            {!isIe && (
                                <span className={'form-control-placeholder'}>
                                    <FormattedMessage id={'placeholder.password'} />
                                </span>
                            )}
                            <InputGroup.Prepend>
                                <InputGroup.Text
                                    onMouseDown={handlePasswordMouseDown}
                                    onMouseUp={handlePasswordMouseUp}
                                    onClick={handlePasswordClick}
                                >
                                    <FontAwesomeIcon icon={isRevealPassword ? ['fal', 'eye-slash'] : ['fal', 'eye']} />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                        </InputGroup>
                    </div>
                    {isInvalid && <Form.Control.Feedback type="invalid">{fieldError}</Form.Control.Feedback>}
                    <p className={'login-window-forgot-password text-right'}>
                        <NavLink
                            to={generatePath(getPath('forgotPassword', locale), { lang: locale })}
                            className="text-muted"
                        >
                            <FormattedMessage id={'login.forgotten_password'} />
                        </NavLink>
                    </p>
                </Form.Group>
                <div className={'login-form-step-footer'}>
                    {previousButtonText && (
                        <Button
                            className={'btn-prev'}
                            variant={'link'}
                            disabled={disabled}
                            onClick={handleOnPreviousButtonClick}
                        >
                            {previousButtonText}
                        </Button>
                    )}
                    {nextButtonText && (
                        <Button
                            variant="primary"
                            className={'btn-next'}
                            disabled={disabled}
                            loading={fetching}
                            onClick={handleOnButtonClick}
                        >
                            {nextButtonText}
                        </Button>
                    )}
                </div>
            </div>
        )
    }
)

export default PasswordFormStep
