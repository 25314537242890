/*
 *
 * Salesmen
 *
 */

import ActionTypes from './constants'
import {
    ContainerActions,
    ContainerState,
    ICustomersListState,
    IProspectsEditState,
    IProspectsListState,
} from './types'

export const initialCustomersState: ICustomersListState = {
    fetching: false,
}
export const initialProspectsState: IProspectsListState = {
    fetching: false,
}
export const initialProspectEditState: IProspectsEditState = {
    fetching: false,
}

export const initialModifyLoginState = {
    fetching: false,
}

export const initialState: ContainerState = {
    customers: initialCustomersState,
    prospects: {
        list: initialProspectsState,
        edit: initialProspectEditState,
    },
    modifyLogin: initialModifyLoginState,
}

function salesmanReducer(state: ContainerState = initialState, action: ContainerActions): ContainerState {
    switch (action.type) {
        case ActionTypes.RESET_ACTION:
            return initialState
        case ActionTypes.CUSTOMER_PROCESS_ACTION: {
            const { page, pageSize, filters, sortBy } = action.payload
            return {
                ...state,
                customers: {
                    ...state.customers,
                    error: undefined,
                    fetching: true,
                    tableState: {
                        page,
                        pageSize,
                        filters,
                        sortBy,
                    },
                },
            }
        }
        case ActionTypes.CUSTOMER_SUCCESS_ACTION: {
            const { response } = action.payload
            const { data } = response

            return {
                ...state,
                customers: {
                    ...state.customers,
                    fetching: false,
                    items: data['hydra:member'] || [],
                    totalItems: data['hydra:totalItems'] || 0,
                    nextPageUrl: data['hydra:view'] ? data['hydra:view']['hydra:next'] : undefined,
                    prevPageUrl: data['hydra:view'] ? data['hydra:view']['hydra:prev'] : undefined,
                    firstPageUrl: data['hydra:view'] ? data['hydra:view']['hydra:first'] : undefined,
                    lastPageUrl: data['hydra:view'] ? data['hydra:view']['hydra:last'] : undefined,
                },
            }
        }
        case ActionTypes.CUSTOMER_FAILURE_ACTION: {
            return {
                ...state,
                customers: {
                    ...state.customers,
                    fetching: false,
                    error: action.payload.error,
                },
            }
        }
        case ActionTypes.PROSPECT_PROCESS_ACTION: {
            const { page, pageSize, filters, sortBy } = action.payload
            return {
                ...state,
                prospects: {
                    ...state.prospects,
                    list: {
                        ...state.prospects.list,
                        fetching: true,
                        tableState: {
                            page,
                            pageSize,
                            filters,
                            sortBy,
                        },
                    },
                },
            }
        }
        case ActionTypes.PROSPECT_SUCCESS_ACTION: {
            const { response } = action.payload
            const { data } = response

            return {
                ...state,
                prospects: {
                    ...state.prospects,
                    list: {
                        ...state.prospects.list,
                        fetching: false,
                        items: data['hydra:member'] || [],
                        totalItems: data['hydra:totalItems'] || 0,
                        nextPageUrl: data['hydra:view'] ? data['hydra:view']['hydra:next'] : undefined,
                        prevPageUrl: data['hydra:view'] ? data['hydra:view']['hydra:prev'] : undefined,
                        firstPageUrl: data['hydra:view'] ? data['hydra:view']['hydra:first'] : undefined,
                        lastPageUrl: data['hydra:view'] ? data['hydra:view']['hydra:last'] : undefined,
                    },
                },
            }
        }
        case ActionTypes.PROSPECT_FAILURE_ACTION: {
            return {
                ...state,
                prospects: {
                    ...state.prospects,
                    list: {
                        ...state.prospects.list,
                        fetching: false,
                        error: action.payload.error,
                    },
                },
            }
        }
        case ActionTypes.PROSPECT_EDIT_PROCESS_ACTION: {
            return {
                ...state,
                prospects: {
                    ...state.prospects,
                    edit: {
                        ...state.prospects.edit,
                        fetching: true,
                        params: action.payload.params,
                        identifier: action.payload.identifier,
                    },
                },
            }
        }
        case ActionTypes.PROSPECT_EDIT_SUCCESS_ACTION: {
            return {
                ...state,
                prospects: {
                    ...state.prospects,
                    edit: {
                        ...state.prospects.edit,
                        fetching: false,
                        data: action.payload.data,
                    },
                },
            }
        }
        case ActionTypes.PROSPECT_EDIT_FAILURE_ACTION: {
            return {
                ...state,
                prospects: {
                    ...state.prospects,
                    edit: {
                        ...state.prospects.edit,
                        fetching: false,
                        error: action.payload.error,
                    },
                },
            }
        }
        case ActionTypes.PROSPECT_EDIT_RESET_ACTION: {
            return {
                ...state,
                prospects: {
                    ...state.prospects,
                    edit: {
                        ...initialState.prospects.edit,
                    },
                },
            }
        }
        case ActionTypes.PROCESS_MODIFY_LOGIN_ACTION: {
            return {
                ...state,
                modifyLogin: {
                    ...state.modifyLogin,
                    done: false,
                },
            }
        }
        case ActionTypes.FAILURE_MODIFY_LOGIN_ACTION: {
            return {
                ...state,
                modifyLogin: {
                    ...state.modifyLogin,
                    tms: Date.now(),
                    fetching: false,
                    error: action.payload.error,
                },
            }
        }
        case ActionTypes.SUCCESS_MODIFY_LOGIN_ACTION: {
            return {
                ...state,
                modifyLogin: {
                    ...state.modifyLogin,
                    done: true,
                },
            }
        }
        case ActionTypes.RESET_MODIFY_LOGIN_ACTION: {
            return {
                ...state,
                modifyLogin: initialModifyLoginState,
            }
        }
        default:
            return state
    }
}

export default salesmanReducer
