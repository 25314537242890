import classNames from 'classnames'
import React from 'react'
import Helmet from 'react-helmet'
import { useIntl } from 'react-intl'
import { generatePath, Redirect, RouteComponentProps } from 'react-router-dom'
import Config from '../config'
import { getPath } from '../routes'
import { isInternetExplorer } from '../utils/browser'
import ForgotPasswordWindow from '../containers/ForgotPasswordWindow/ForgotPasswordWindow'
import { isLoggedIn } from '../services/api/service/authenticate/jwt'

const locales: Array<string> = Config.I18N.LANGUAGES

function ForgotPasswordPage(props: RouteComponentProps): JSX.Element {
    const { locale, formatMessage } = useIntl()

    if (isLoggedIn()) {
        return <Redirect to={'/'} />
    }

    return (
        <div className={classNames('login-window-wrapper', { 'is-ie': isInternetExplorer() })}>
            <Helmet>
                <title>{formatMessage({ id: 'login.forgot_password_title' })}</title>
                <meta name="description" content={formatMessage({ id: 'login.forgot_password_help' })} />
                <link rel="canonical" href={generatePath(getPath('resetPassword', locale), { lang: locale })} />
                {locales
                    .filter((lcl) => {
                        return lcl !== locale
                    })
                    .map((lcl) => {
                        return (
                            <link
                                rel="alternate"
                                href={generatePath(getPath('login', lcl), {
                                    lang: lcl,
                                })}
                                hrefLang={lcl}
                                key={lcl}
                            />
                        )
                    })}
                <body className={'login-page forgot-password'} />
            </Helmet>
            <ForgotPasswordWindow />
        </div>
    )
}

export default ForgotPasswordPage
