import { ILoginChangePasswordFormData, ILoginStepProps } from '../types'
import React, { forwardRef, MouseEvent, useMemo, useState } from 'react'
import classNames from 'classnames'
import { FormattedMessage, useIntl } from 'react-intl'
import Button from '../../../components/Buttons/Button'
import * as yup from 'yup'
import PasswordField from '../../../components/Auth/PasswordField'
import { Alert } from 'react-bootstrap'
import { useFormContext, useFormState } from 'react-hook-form'
import FlatIcon from '../../../components/Icon/FlatIcon'

type IProps = ILoginStepProps<ILoginChangePasswordFormData> & {
    required: boolean
}

const PasswordRequirement: React.FunctionComponent<{
    labelKey: string
    labelValues?: Record<string, React.ReactNode>
    valid: boolean
}> = function ({ labelKey, labelValues, valid }) {
    return (
        <div
            className={classNames('password-requirement d-flex', {
                invalid: !valid,
                valid: valid,
            })}
        >
            <FlatIcon icon={valid ? 'check' : 'warning'} className="mr-3" />
            <span>
                <FormattedMessage id={labelKey} values={labelValues} />
            </span>
        </div>
    )
}

const PasswordRequirements: Record<
    string,
    {
        labelKey: string
        labelValues?: Record<string, React.ReactNode>
        validator: (value: string) => boolean
    }
> = {
    length: {
        labelKey: 'password.requirements.length',
        validator: (value) => value.length >= 10,
        labelValues: {
            length: 10,
        },
    },
    strength: {
        labelKey: 'password.requirements.strength',
        validator: (value) => {
            value = value.trim()
            return (
                !!value.match(/[a-z]/) &&
                !!value.match(/[A-Z]/) &&
                !!value.match(/[0-9]/) &&
                !!value.match(/[^a-zA-Z0-9]/)
            )
        },
    },
}

const LoginChangePasswordFormStep = forwardRef<HTMLInputElement, IProps>(
    ({ disabled = false, register, required, step, onValidated }) => {
        const { setError, clearErrors } = useFormContext()
        const { errors } = useFormState()
        const [currentOldPasswordValue, setCurrentOldPasswordValue] = useState<string>('')
        const [currentNewPasswordValue, setCurrentNewPasswordValue] = useState<string>('')
        const { formatMessage } = useIntl()
        const oldPasswordError = errors.old_password?.message || ''
        const newPasswordError = errors.new_password?.message || ''
        const isInvalid = oldPasswordError.length > 0 || newPasswordError.length > 0

        const handleOnOldPasswordValueChange = (value: string) => {
            setCurrentOldPasswordValue(value)
        }

        const handleOnNewPasswordValueChange = (value: string) => {
            setCurrentNewPasswordValue(value)
        }

        const constraints = useMemo(() => {
            return yup.object().shape({
                old_password: required
                    ? yup.string().required(formatMessage({ id: 'modify_login.current_password_required' }))
                    : yup.string(),
                new_password: required
                    ? yup
                          .string()
                          .required(formatMessage({ id: 'modify_login.new_password_required' }))
                          .test(
                              'password-requirements',
                              formatMessage({ id: 'modify_login.new_password_invalid' }),
                              (value) => {
                                  if (!value) {
                                      return true
                                  }

                                  return Object.keys(PasswordRequirements).reduce<boolean>(
                                      (valid, k) => valid && PasswordRequirements[k].validator(value),
                                      true
                                  )
                              }
                          )
                    : yup.string(),
            })
        }, [required, formatMessage])

        const validateFields = (oldPassword: string, newPassword: string) => {
            const val = {
                old_password: oldPassword,
                new_password: newPassword,
            }
            constraints
                .validate(val, { abortEarly: false })
                .catch((err) => {
                    ;['old_password', 'new_password'].forEach((k) => {
                        const message = err.inner.find((e) => e.path === k)?.message || ''

                        if (message.length > 0) {
                            setError(k, { message })
                        } else {
                            clearErrors(k)
                        }
                    })
                })
                .then((valid) => {
                    if (valid) {
                        clearErrors(['old_password', 'new_password'])
                        onValidated(valid as ILoginChangePasswordFormData, step)
                    }
                })
        }

        const handleOnButtonClick = (e: MouseEvent) => {
            validateFields(currentOldPasswordValue, currentNewPasswordValue)
        }

        const handleValidate = () => {
            validateFields(currentOldPasswordValue, currentNewPasswordValue)
        }

        return (
            <div className={classNames('login-form-step', 'login-form-step-4', { 'is-invalid': isInvalid })}>
                <Alert variant="warning" className={'mb-3'}>
                    <FormattedMessage
                        id={'login.change_password.message'}
                        values={{ date: '18/02/2025', hour_start: '18h00', hour_end: '18h30' }}
                    />
                </Alert>
                <PasswordField
                    register={register}
                    name="old_password"
                    isFullFilled={currentOldPasswordValue.length > 0}
                    isInvalid={oldPasswordError.length > 0}
                    fieldError={oldPasswordError}
                    placeholder={'placeholder.actual_password'}
                    handleValueChanged={handleOnOldPasswordValueChange}
                    handleValidate={handleValidate}
                />

                <PasswordField
                    register={register}
                    name="new_password"
                    isFullFilled={currentNewPasswordValue.length > 0}
                    isInvalid={newPasswordError.length > 0}
                    fieldError={newPasswordError}
                    placeholder={'placeholder.new_password'}
                    handleValueChanged={handleOnNewPasswordValueChange}
                    handleValidate={handleValidate}
                />
                <div className="password-requirements">
                    {Object.keys(PasswordRequirements).map((k) => (
                        <PasswordRequirement
                            key={`password-req-${k}`}
                            labelKey={PasswordRequirements[k].labelKey}
                            labelValues={PasswordRequirements[k].labelValues}
                            valid={PasswordRequirements[k].validator(currentNewPasswordValue)}
                        />
                    ))}
                </div>

                <div className={'login-form-step-footer'}>
                    <Button className={'btn-next'} variant="primary" disabled={disabled} onClick={handleOnButtonClick}>
                        <FormattedMessage id={'default.save'} />
                    </Button>
                </div>
            </div>
        )
    }
)

export default LoginChangePasswordFormStep
