import { ISalesmanMenu, ISalesmanMenuItemType } from '../types/salesman'
import { IMe } from '../services/api/service/me/types'
import { isStatsMode } from '../store/salesmens/utils'
import { ISalesmanStatsMode } from '../services/api/service/salesman/types'

export default function getSalesmanMenu(me: IMe): ISalesmanMenu {
    let items: ISalesmanMenu = [
        {
            type: ISalesmanMenuItemType.Home,
            label: 'salesman.home',
            route: 'salesmanArea',
            icon: 'home',
            exact: true,
            position: 10,
        },
        {
            type: ISalesmanMenuItemType.Customers,
            label: 'salesman.customers',
            route: 'salesmanCustomers',
            icon: 'handshake',
            exact: true,
            position: 30,
        },
        {
            type: ISalesmanMenuItemType.Prospects,
            label: 'salesman.prospects',
            route: 'salesmanProspects',
            icon: 'id-card',
            exact: true,
            position: 40,
        },
        {
            type: ISalesmanMenuItemType.Carts,
            label: 'customer.carts',
            route: 'salesmanCarts',
            icon: 'shopping-cart',
            exact: true,
            position: 80,
        },
        {
            type: ISalesmanMenuItemType.Modify_Login,
            label: 'default.password',
            route: 'salesmanModifyLogin',
            icon: 'settings',
            exact: true,
            position: 90,
        },
        {
            type: ISalesmanMenuItemType.Logout,
            label: 'default.logout',
            route: 'logout',
            icon: 'logout',
            exact: true,
            position: 100,
        },
    ]

    if (!isStatsMode(me, ISalesmanStatsMode.NoStats)) {
        items = [
            ...items,
            {
                type: ISalesmanMenuItemType.Dashboard,
                label: 'salesman.statistics',
                route: 'salesmanDashboard',
                icon: 'statistics',
                exact: true,
                position: 20,
            },
            {
                type: ISalesmanMenuItemType.Assets,
                label: 'order_mode.assets',
                route: 'salesmanAssets',
                icon: 'budget',
                exact: false,
                position: 50,
            },
            {
                type: ISalesmanMenuItemType.Orders,
                label: 'order_mode.orders',
                route: 'salesmanOrders',
                icon: 'list',
                sub_icon: 'check',
                exact: false,
                position: 60,
            },
            {
                type: ISalesmanMenuItemType.Invoices,
                label: 'salesman_customer.invoices',
                route: 'salesmanInvoices',
                icon: 'receipt',
                exact: true,
                position: 70,
            },
        ]
    }

    return items.sort((a, b) => {
        if (a.position < b.position) {
            return -1
        } else if (a.position > b.position) {
            return 1
        } else {
            return 0
        }
    })
}
