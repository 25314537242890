import { FormState } from 'react-hook-form'
import { FieldValues } from 'react-hook-form/dist/types/fields'
import isObject from 'lodash/isObject'
import has from 'lodash/has'

export function isInvalidField<TFieldValues extends FieldValues>(
    fieldName: keyof TFieldValues,
    formState: FormState<TFieldValues>
): boolean {
    const { errors, submitCount, isSubmitted } = formState
    // @ts-ignore
    return submitCount > 0 && isSubmitted && has(errors, fieldName) && isObject(errors[fieldName])
}

export function isValidField<TFieldValues extends FieldValues>(
    fieldName: keyof TFieldValues,
    formState: FormState<TFieldValues>
): boolean {
    const { errors, submitCount, isSubmitted } = formState
    // @ts-ignore
    return submitCount > 0 && isSubmitted && (!has(errors, fieldName) || !isObject(errors[fieldName]))
}
