import { ILoginStepProps } from '../../containers/LoginWindow/types'
import React, { FunctionComponent, useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import classNames from 'classnames'
import { FormattedMessage, useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ApplicationHelper from '../../utils/applicationHelper'
import { isInternetExplorer } from '../../utils/browser'

type IProps = Pick<ILoginStepProps<any>, 'register'> & {
    name: string
    placeholder: string
    handleValueChanged: (value: string) => void
    handleValidate: () => void
    isInvalid?: boolean
    isFullFilled?: boolean
    disabled?: boolean
    fieldError?: string
}

const PasswordField: FunctionComponent<IProps> = ({
    register,
    name,
    placeholder,
    handleValueChanged,
    handleValidate,
    isInvalid = false,
    isFullFilled = false,
    disabled = false,
    fieldError = '',
}): JSX.Element => {
    const [isRevealPassword, setIsRevealPassword] = useState<boolean>(false)
    const isIe = isInternetExplorer()
    const { formatMessage } = useIntl()

    const handlePasswordMouseDown = () => {
        if (ApplicationHelper.isTouchScreen()) {
            return
        }
        setIsRevealPassword(true)
    }

    const handlePasswordMouseUp = () => {
        if (ApplicationHelper.isTouchScreen()) {
            return
        }
        setIsRevealPassword(false)
    }
    const handlePasswordClick = () => {
        if (!ApplicationHelper.isTouchScreen()) {
            return
        }
        setIsRevealPassword((state) => !state)
    }
    const handleOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleValidate()
        }
    }
    const handleOnFieldValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleValueChanged(e.target.value)
    }

    return (
        <Form.Group>
            <div
                className={classNames(
                    'form-group-inner',
                    { 'is-invalid': isInvalid },
                    { 'is-fullfilled': isFullFilled }
                )}
            >
                <InputGroup>
                    <Form.Control
                        size="lg"
                        type={isRevealPassword ? 'text' : 'password'}
                        autoComplete="off"
                        spellCheck={false}
                        autoCapitalize={'none'}
                        isInvalid={isInvalid}
                        onKeyPress={handleOnKeyPress}
                        disabled={disabled}
                        className={classNames({ 'is-invalid': isInvalid }, { 'has-value': isFullFilled })}
                        placeholder={isIe ? formatMessage({ id: placeholder }) : ''}
                        {...register(name, { required: true, onChange: handleOnFieldValueChange })}
                    />
                    {!isIe && (
                        <span className={'form-control-placeholder'}>
                            <FormattedMessage id={placeholder} />
                        </span>
                    )}
                    <InputGroup.Prepend>
                        <InputGroup.Text
                            onMouseDown={handlePasswordMouseDown}
                            onMouseUp={handlePasswordMouseUp}
                            onClick={handlePasswordClick}
                        >
                            <FontAwesomeIcon icon={isRevealPassword ? ['fal', 'eye-slash'] : ['fal', 'eye']} />
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                </InputGroup>
            </div>
            {isInvalid && <Form.Control.Feedback type="invalid">{fieldError}</Form.Control.Feedback>}
        </Form.Group>
    )
}

export default PasswordField
