import React, { forwardRef, MouseEvent, useMemo, useState } from 'react'
import { Form } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import classNames from 'classnames'
import Button from '../../../components/Buttons/Button'
import { ILoginStepProps, ILoginPassword2FaStepFormData } from '../types'
import * as yup from 'yup'
import { isInternetExplorer } from '../../../utils/browser'

type IProps = ILoginStepProps<ILoginPassword2FaStepFormData> & {
    mobilePhone?: string | null
    email?: string | null
    required: boolean
}

const Login2FaFormStep = forwardRef<HTMLInputElement, IProps>(
    ({ disabled = false, required, register, step, onValidated, mobilePhone, email }) => {
        const [currentValue, setCurrentValue] = useState<string>('')
        const [currentRememberDeviceValue, setCurrentRememberDeviceValue] = useState<boolean>(false)
        const [fieldError, setFieldError] = useState<string>('')
        const isFullFilled = currentValue.length > 0
        const isInvalid = fieldError.length > 0
        const isIe = isInternetExplorer()
        const { formatMessage } = useIntl()

        const constraints = useMemo(() => {
            return yup.object().shape({
                code: required ? yup.string().required(formatMessage({ id: 'login.code_required' })) : yup.string(),
                remember_device: yup.boolean(),
            })
        }, [required, formatMessage])

        const handleOnFieldValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            setCurrentValue(e.target.value)
        }

        const handleOnRememberDeviceValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            setCurrentRememberDeviceValue(e.target.checked)
        }

        const validateField = (value: string, rememberDevice: boolean) => {
            const val = {
                code: value,
                remember_device: rememberDevice,
            }
            constraints
                .validate(val)
                .catch((err) => {
                    setFieldError(err.message)
                })
                .then((valid) => {
                    if (valid) {
                        setFieldError('')
                        onValidated(valid as ILoginPassword2FaStepFormData, step)
                    }
                })
        }

        const handleOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
                validateField(currentValue, currentRememberDeviceValue)
            }
        }

        const handleOnButtonClick = (e: MouseEvent) => {
            validateField(currentValue, currentRememberDeviceValue)
        }

        return (
            <div
                className={classNames(
                    'login-form-step',
                    'login-form-step-3',
                    { 'is-invalid': isInvalid },
                    { 'is-full-filled': isFullFilled }
                )}
            >
                <p className={'login-window-2fa-mode'}>
                    {mobilePhone && (
                        <FormattedMessage id={'login.2fa_info.sms'} values={{ mobile_phone: mobilePhone || 'N.C' }} />
                    )}
                    {email && <FormattedMessage id={'login.2fa_info.email'} values={{ email: email || 'N.C' }} />}
                </p>
                <Form.Group>
                    <div className={classNames('form-group-inner', { 'is-invalid': isInvalid })}>
                        <Form.Control
                            autoFocus
                            size="lg"
                            type="text"
                            autoComplete="off"
                            spellCheck={false}
                            autoCapitalize={'none'}
                            disabled={disabled}
                            isInvalid={isInvalid}
                            onKeyPress={handleOnKeyPress}
                            className={classNames({ 'is-invalid': isInvalid }, { 'has-value': isFullFilled })}
                            placeholder={isIe ? formatMessage({ id: 'placeholder.authenticate_code' }) : ''}
                            {...register('code', { required, onChange: handleOnFieldValueChange })}
                        />
                        {!isIe && (
                            <span className={'form-control-placeholder'}>
                                <FormattedMessage id={'placeholder.authenticate_code'} />
                            </span>
                        )}
                    </div>
                    {isInvalid && <Form.Control.Feedback type="invalid">{fieldError}</Form.Control.Feedback>}
                </Form.Group>

                <Form.Group className={'2fa-remember-device'}>
                    <Form.Check
                        id={'login_2fa_remember_device'}
                        type={'switch'}
                        disabled={disabled}
                        label={formatMessage({
                            id: 'login.2fa_info.remember_device',
                        })}
                        value={1}
                        {...register('remember_device', { onChange: handleOnRememberDeviceValueChange })}
                    />
                </Form.Group>

                <div className={'login-form-step-footer'}>
                    <Button className={'btn-next'} variant="primary" disabled={disabled} onClick={handleOnButtonClick}>
                        <FormattedMessage id={'login.connection'} />
                    </Button>
                </div>
            </div>
        )
    }
)

export default Login2FaFormStep
