import { call, getContext, put, select, takeLatest } from 'redux-saga/effects'
import AuthActionTypes from './constants'
import {
    authFailure2FaAction,
    authFailureAction,
    authFailureChangePasswordAction,
    authLoggedAction,
    authWait2FaAction,
    authWaitChangePasswordAction,
} from './actions'
import { IApiClient } from '../../services/api/types'
import { push } from 'connected-react-router'
import { generatePath } from 'react-router-dom'
import { getPath } from '../../routes'
import { selectLocale } from '../intl/selectors'
import { appReset, formatAppError } from '../app/saga'
import { reloadingAction } from '../app/actions'
import { IAuthLogoutAction, IAuthProcess2FaAction, IAuthProcessAction, IAuthProcessChangePasswordAction } from './types'
import Config from '../../config'
import LogRocket from 'logrocket'
import * as Sentry from '@sentry/react'
import localforage from 'localforage'
import { IApiMeResponse, IMe } from '../../services/api/service/me/types'
import HttpStatusCode, { HttpAuthErrors } from '../http/codes'
import AppError from '../app/error'
import { customersResetAllAction } from '../customers/actions'
import { makeSelect2FaToken, makeSelectChangePasswordToken } from './selectors'
import { IApiAuthResponse } from '../../services/api/service/authenticate/types'

function* processAuth2FaLogin(action: IAuthProcess2FaAction) {
    const api: IApiClient = yield getContext('api')
    const token2Fa = yield select(makeSelect2FaToken())

    const { code, rememberDevice } = action.payload

    try {
        const response2fa: IApiAuthResponse = yield call(
            { context: api.authenticate, fn: 'check2Fa' },
            {
                code,
                remember_device: rememberDevice,
            },
            token2Fa
        )

        if (response2fa.data.require_change_password) {
            yield put(authWaitChangePasswordAction(response2fa.data.token))
            return
        }

        // récupération me
        const response: IApiMeResponse = yield call({ context: api.me, fn: 'me' })

        const me: IMe = response.data

        // on stocke le user connecté + info de redirect et origin
        yield put(authLoggedAction(me))

        // on reload l'application
        yield put(reloadingAction())
    } catch (e) {
        // erreur par défaut
        let err = yield call(formatAppError, e, 'login.auth.error')
        if (
            e.isAxiosError &&
            e.response &&
            [HttpStatusCode.BAD_REQUEST, ...HttpAuthErrors].indexOf(e.response.status) > -1
        ) {
            err = new AppError(e.response.data['message'] || e.message, e.response.status, undefined, e)
        }
        yield put(authFailure2FaAction(err))
    }
}

function* processAuthLogin(action: IAuthProcessAction) {
    const api: IApiClient = yield getContext('api')

    try {
        // on tente une connexion
        const response: IApiAuthResponse = yield call({ context: api.authenticate, fn: 'login' }, action.payload)
        const { require_2fa_authentication, require_change_password, token, mobile_phone, email } = response.data

        // double auth ? si oui, on s'arrête ici !
        if (require_2fa_authentication) {
            yield put(authWait2FaAction(token, mobile_phone, email))
            return
        }

        // changement de mot de passe ? si oui, on s'arrête ici !
        if (require_change_password) {
            yield put(authWaitChangePasswordAction(token))
            return
        }

        // récupération me
        const meResponse: IApiMeResponse = yield call({ context: api.me, fn: 'me' })
        const me: IMe = meResponse.data

        // on stocke le user connecté + info de redirect et origin
        yield put(authLoggedAction(me))

        // on reload l'application
        yield put(reloadingAction())
    } catch (e) {
        // erreur par défaut
        let err = yield call(formatAppError, e, 'login.auth.error')
        if (
            e.isAxiosError &&
            e.response &&
            [HttpStatusCode.BAD_REQUEST, ...HttpAuthErrors].indexOf(e.response.status) > -1
        ) {
            err = new AppError(e.response.data['message'] || e.message, e.response.status, undefined, e)
        }
        yield put(authFailureAction(err))
    }
}

function* processAuthChangePassword(action: IAuthProcessChangePasswordAction) {
    const api: IApiClient = yield getContext('api')
    const tokenChangePassword = yield select(makeSelectChangePasswordToken())

    const { oldPassword, newPassword } = action.payload

    try {
        yield call(
            { context: api.authenticate, fn: 'changePassword' },
            {
                old_password: oldPassword,
                new_password: newPassword,
            },
            tokenChangePassword
        )

        // récupération me
        const response: IApiMeResponse = yield call({ context: api.me, fn: 'me' })

        const me: IMe = response.data

        // on stocke le user connecté + info de redirect et origin
        yield put(authLoggedAction(me))

        // on reload l'application
        yield put(reloadingAction())
    } catch (e) {
        // erreur par défaut
        let err = yield call(formatAppError, e, 'login.auth.error')
        if (e.isAxiosError && e.response && HttpAuthErrors.indexOf(e.response.status) > -1) {
            err = new AppError(e.response.data['message'] || e.message, e.response.status, undefined, e)
        }

        yield put(authFailureChangePasswordAction(err))
    }
}

function* processAuthLogout(action: IAuthLogoutAction) {
    const api: IApiClient = yield getContext('api')
    const locale: string = yield select(selectLocale)
    const { reason, origin, auto } = action.payload

    const whitelistUrls: string[] = []

    const loginUrl = generatePath(getPath('login', locale), { lang: locale })
    const forgotPasswordUrl = generatePath(getPath('forgotPassword', locale), { lang: locale })
    const resetPasswordUrl = generatePath(getPath('resetPassword', locale), { lang: locale })

    whitelistUrls.push(loginUrl)
    whitelistUrls.push(forgotPasswordUrl)
    whitelistUrls.push(resetPasswordUrl)

    let redirectUrl = loginUrl
    let isWhitelistUrl = false
    for (const whitelistUrl of whitelistUrls) {
        if (window.location.pathname.indexOf(whitelistUrl) > -1) {
            isWhitelistUrl = true
            break
        }
    }

    if (origin) {
        redirectUrl = `${loginUrl}?origin=${origin}`
    } else if (auto) {
        if (isWhitelistUrl) {
            redirectUrl = `${window.location.pathname}${window.location.search}`
        } else if (loginUrl !== window.location.pathname) {
            redirectUrl = `${loginUrl}?origin=${window.location.pathname}`
        }
    }

    yield put(push(redirectUrl))

    const needReloadPage = !isWhitelistUrl

    try {
        const storage: typeof localforage = yield getContext('storage')
        // console.log('auth/saga::processAuthLogout => remove store id')
        yield call({ context: storage, fn: 'removeItem' }, Config.AUTH.STORE_ID_STORAGE_NAME)
        yield call({ context: storage, fn: 'removeItem' }, Config.AUTH.CUSTOMER_ID_STORAGE_NAME)
    } catch (e) {
        console.error(e)
        if (Config.SENTRY.ACTIVE) {
            Sentry.captureException(e)
        }
    }

    yield put(customersResetAllAction())

    // Log Rocket
    if (Config.LOG_ROCKET.ACTIVE) {
        LogRocket.identify({
            locale: locale,
            name: 'anonymous',
            country_name: 'NC',
        })
    }

    if (Config.SENTRY.ACTIVE) {
        Sentry.setUser(null)
    }

    yield call({ context: api.authenticate, fn: 'logout' })
    yield call({ context: api, fn: 'setCustomerId' })
    yield call({ context: api, fn: 'setCartMode' })
    yield call({ context: api, fn: 'setOrderMode' })

    yield call(appReset)

    if (reason) {
        const error = yield call(formatAppError, reason, 'default.account_disconnected')
        yield put(authFailureAction(error))
    }

    if (needReloadPage && auto) {
        window.location.href = redirectUrl
        return
    }
}

function* processLoggedAction() {}

export default [
    takeLatest(AuthActionTypes.PROCESS_ACTION, processAuthLogin),
    takeLatest(AuthActionTypes.PROCESS_2FA_ACTION, processAuth2FaLogin),
    takeLatest(AuthActionTypes.PROCESS_CHANGE_PASSWORD_ACTION, processAuthChangePassword),
    takeLatest(AuthActionTypes.LOGOUT_ACTION, processAuthLogout),
    takeLatest(AuthActionTypes.LOGGED_ACTION, processLoggedAction),
]
