import { call, cancelled, getContext, put, takeLatest, select } from 'redux-saga/effects'
import ActionTypes from './constants'
import {
    ICustomerProspectTableState,
    ISalesmanModifyLoginProcessAction,
    ISalesmenCustomersProcessAction,
    ISalesmenEditProspectProcessAction,
    ISalesmenProspectsProcessAction,
    ISalesmenProspectsRefreshAction,
} from './types'
import { IApiClient, IBasePaginationParameters } from '../../services/api/types'
import axios from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import { formatAppError } from '../app/saga'
import { IApiCustomerListParameters, IApiCustomerListResponse } from '../../services/api/service/customers/types'
import {
    salesmanModifyLoginFailureAction,
    salesmanModifyLoginSuccessAction,
    salesmenCustomersFailureAction,
    salesmenCustomersSuccessAction,
    salesmenProspectEditFailureAction,
    salesmenProspectEditSuccessAction,
    salesmenProspectsFailureAction,
    salesmenProspectsSuccessAction,
} from './actions'
import { $PropertyType } from 'utility-types'
import { makeSelectProspectsTableState } from './selectors'
import {
    IApiProspectEditResponse,
    IApiProspectListParameters,
    IApiProspectListResponse,
    ISalesmanProspectList,
} from '../../services/api/service/prospects/types'
const CancelToken = axios.CancelToken

function* processCustomersProcess(action: ISalesmenCustomersProcessAction) {
    const api: IApiClient = yield getContext('api')
    const source = CancelToken.source()
    const { page, pageSize, filters, sortBy } = action.payload

    try {
        const orderBy: $PropertyType<IBasePaginationParameters, 'order'> = {}

        // mapping entre les colonne du tableau et la réalité
        const mappingKeys = {
            store_code: 'login',
            address1: 'address',
            country_name: 'country.name',
        }

        sortBy.forEach((sb) => {
            const orderKey = mappingKeys[sb.id] || sb.id
            orderBy[orderKey] = sb.desc ? 'desc' : 'asc'
        })

        const params: IApiCustomerListParameters = {
            page,
            itemsPerPage: pageSize,
            order: orderBy,
            prospect: 0,
        }

        filters.forEach((filter) => {
            const filterKey = mappingKeys[filter.id] || filter.id
            params[filterKey] = filter.value
        })

        const response: IApiCustomerListResponse = yield call(
            { context: api.customers, fn: 'list' },
            params,
            source.token
        )

        yield put(salesmenCustomersSuccessAction(response))
    } catch (e) {
        const error = yield call(formatAppError, e, 'salesmen_customers.unknown_error')
        yield put(salesmenCustomersFailureAction(error))
    } finally {
        if (yield cancelled()) {
            source.cancel('cancelled')
        }
    }
}

function* processEditProspectProcess(action: ISalesmenEditProspectProcessAction) {
    const api: IApiClient = yield getContext('api')
    const source = CancelToken.source()
    const { params, identifier } = action.payload

    try {
        let response: IApiProspectEditResponse
        const parameters = cloneDeep(params)
        if (identifier) {
            response = yield call({ context: api.prospects, fn: 'edit' }, parameters, identifier)
        } else {
            response = yield call({ context: api.prospects, fn: 'create' }, parameters)
        }
        yield put(salesmenProspectEditSuccessAction(response.data))
    } catch (e) {
        const error = yield call(formatAppError, e, 'salesmen_edit_prospect.unknown_error')
        yield put(salesmenProspectEditFailureAction(error))
    } finally {
        if (yield cancelled()) {
            source.cancel('cancelled')
        }
    }
}

function* processProspectsRefreshProcess(action: ISalesmenProspectsRefreshAction) {
    const { page } = action.payload
    const tstate: ICustomerProspectTableState = yield select(makeSelectProspectsTableState())

    // récupération des infos du tableau
    const payload = { ...tstate, page }
    // on dispatch la bonne action !
    yield put({
        type: ActionTypes.PROSPECT_PROCESS_ACTION,
        payload,
    })
}

function* processProspectsProcess(action: ISalesmenProspectsProcessAction) {
    const api: IApiClient = yield getContext('api')
    const source = CancelToken.source()
    const { page, pageSize, filters, sortBy } = action.payload

    try {
        const orderBy: $PropertyType<IBasePaginationParameters, 'order'> = {}
        sortBy.forEach((sb) => {
            let orderKey = sb.id
            if (sb.id === 'store_code') {
                orderKey = 'login'
            }
            orderBy[orderKey] = sb.desc ? 'desc' : 'asc'
        })
        const params: IApiProspectListParameters = {
            page,
            itemsPerPage: pageSize,
            order: orderBy,
        }
        filters.forEach((filter) => (params[filter.id === 'store_code' ? 'login' : filter.id] = filter.value))

        const response: IApiProspectListResponse<Array<ISalesmanProspectList>> = yield call(
            { context: api.prospects, fn: 'list' },
            params,
            source.token
        )
        yield put(salesmenProspectsSuccessAction(response))
    } catch (e) {
        const error = yield call(formatAppError, e, 'salesmen_customers.unknown_error')
        yield put(salesmenProspectsFailureAction(error))
    } finally {
        if (yield cancelled()) {
            source.cancel('cancelled')
        }
    }
}

function* processModifyLoginRequest(action: ISalesmanModifyLoginProcessAction) {
    const api: IApiClient = yield getContext('api')
    try {
        const params = action.payload
        yield call({ context: api.salesman, fn: 'changePassword' }, params)
        yield put(salesmanModifyLoginSuccessAction())
    } catch (e) {
        yield put(salesmanModifyLoginFailureAction(e))
    }
}

export default [
    takeLatest(ActionTypes.CUSTOMER_PROCESS_ACTION, processCustomersProcess),
    takeLatest(ActionTypes.PROSPECT_PROCESS_ACTION, processProspectsProcess),
    takeLatest(ActionTypes.PROSPECT_REFRESH_ACTION, processProspectsRefreshProcess),
    takeLatest(ActionTypes.PROSPECT_EDIT_PROCESS_ACTION, processEditProspectProcess),
    takeLatest(ActionTypes.PROCESS_MODIFY_LOGIN_ACTION, processModifyLoginRequest),
]
